import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {loadApiDocumentationJSON, preparePath} from './apiList'
import {CopyButton} from '../CopyButton'
import {Tab} from '../Tab'
import {BACKEND} from '~/config'
import {trl, interfaceLanguage} from '~/services/intl'

import type {Method, RequestBody as RequestBodyType} from './apiList'

export const initialRouteDocumentationPage = '/documentation'
export function ApiPage() {
    const {kind = '', topic = ''} = useParams()
    const [method, setMethod] = useState<Method>()
    const [selectedTab, setSelectedTab] = useState<string>()
    const navigate = useNavigate()

    useEffect(() => {
        loadApiDocumentationJSON(interfaceLanguage)
            .then(apiMethods => {
                const method = apiMethods[kind].find(item => preparePath(item.method.type + item.method.url) == topic)

                if (!method)
                    throw 'error'

                setMethod(method)
            })
            .catch(() => navigate(initialRouteDocumentationPage))
    }, [kind, topic, navigate])

    const tabs = [
        {value: 'search', label: trl('Search-параметры'), visible: !!method?.parameters?.search},
        {value: 'path', label: trl('Path-параметры'), visible: !!method?.parameters?.path},
        {value: 'requestBody', label: trl('Тело запроса'), visible: !!method?.parameters?.requestBody},
    ]
        .filter(tab => tab.visible)

    useEffect(() => {
        !tabs.find(item => selectedTab == item.value) && setSelectedTab(tabs[0]?.value)
    }, [tabs, selectedTab])

    if (!method)
        return null

    const url = `${BACKEND.audioUrlPrefix}${kind == 'oauth' ? '/oauth' : '/api/v1'}${method.method.url}`
    const {description, method: methodApi, parameters, responses} = method

    return (
        <div className='documentation__text api'>
            <div className='documentation__text-wrapper'>
                <div className='documentation__text-content'>
                    <h3>{description}</h3>
                    <div className='row row_gap-5 row_offset-5'>
                        <div className={clsx('api__method-type text text_uppercase', `api__method-type_${method.method.type}`)}>
                            {methodApi.type}
                        </div>
                        <div>
                            {url}
                        </div>
                        <CopyButton>
                            {url}
                        </CopyButton>
                    </div>
                    {parameters && <>
                        <div className='api__subtitle'>
                            {trl('Запрос')}
                        </div>
                        {!!tabs.length &&
                            <Tab
                                tabs={tabs}
                                onTabChange={tab => setSelectedTab(tab)}
                                activeTab={selectedTab}
                                classNamesWrapper='row row_offset-5 row_gap-5'
                            />
                        }
                        {selectedTab == 'search' &&
                            <div className='row row_offset-5 row_gap-5'>
                                {parameters.search?.map(({name, type, description}, index) =>
                                    <Param
                                        key={index}
                                        name={name}
                                        type={type}
                                        description={description}
                                    />
                                )}
                            </div>
                        }
                        {selectedTab == 'path' &&
                            <div className='row row_offset-5 row_gap-5'>
                                {parameters.path?.map(({name, type, description}, index) =>
                                    <Param
                                        key={index}
                                        name={name}
                                        type={type}
                                        description={description}
                                    />
                                )}
                            </div>
                        }
                        {selectedTab == 'requestBody' && parameters.requestBody &&
                            <div className='row row_offset-5 row_gap-5'>
                                <RequestBody requestBody={parameters.requestBody}/>
                            </div>
                        }
                    </>}
                    <div className='api__subtitle'>
                        {trl('Ответ')}
                    </div>
                    <div className='row row_gap-5 row_offset-5'>
                        {responses.map(({code, description, response, title, contentType}, index) =>
                            <Accordion
                                key={index}
                                code={code}
                                title={title}
                                description={description}
                                contentType={contentType}
                                json={response}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

type PropsParam = {
    name: string
    type: string
    description: string
}

function Param({name, type, description}: PropsParam) {
    return (
        <div className='grid api__grid-template'>
            <div className='grid grid_rg-0'>
                <span className='api__parameter'>
                    {name}
                </span>
            </div>
            <div className='grid grid_rg-0'>
                <span className='api__type'>
                    {type}
                </span>
                <span className='api__parameter-description' title={description}>
                    {description}
                </span>
            </div>
        </div>
    )
}

type AccordionProps = {
    code: number
    description?: string
    title: string
    contentType?: string
    json: unknown
}

function Accordion({code, description, json, title, contentType}: AccordionProps) {
    const [open, setOpen] = useState(false)

    return (
        <div className='api__accordion'>
            <button
                className='api__accordion-header row row_between row_nowrap row_gap-5'
                onClick={() => setOpen(!open)}
            >
                <div className='row row_gap-3'>
                    <span className={clsx('api__code', code >= 299 && 'api__code_error')}>
                        {code}
                    </span>
                    <span>
                        {title}
                    </span>
                </div>
                <div>
                    <i className={clsx('pi  api__accordion-icon', open ? 'pi-angle-up' : 'pi-angle-down')}/>
                </div>
            </button>
            {open &&
                <div className='api__accordion-body grid grid_rg-4'>
                    {description && <div>{description}</div>}
                    {contentType && <div className='text text_grey'>{contentType}</div>}
                    {!!json && <div className='api__accordion-response'>
                        <pre>
                            {JSON.stringify(json, null, 4)}
                        </pre>
                    </div>}
                </div>
            }
        </div>
    )
}

type MultiPart = {
    name: string
    type: string
    description: string
}[] | undefined

function RequestBody({requestBody}: {requestBody: RequestBodyType}) {
    const renderApplicationJSON = () => <>
        <div className='row row_gap-5 row_nowrap row_fit'>
            <div className='row row_gap-1'>
                <span>Request body</span>
                <span className='text text_assertive'>
                    {requestBody.required && 'required'}
                </span>
            </div>
            <CopyButton>
                {JSON.stringify(requestBody.value, null, 4)}
            </CopyButton>
        </div>
        <div className='text text_small'>
            Content-type: {requestBody.contentType}
        </div>
        <pre style={{fontFamily: 'monospace', wordBreak: 'break-all', fontSize: '14px'}}>
            {JSON.stringify(requestBody.value, null, 4)}
        </pre>
    </>

    const renderMultiPart = () => <>
        <div className='row row_gap-5 row_nowrap row_fit'>
            <div className='row row_gap-1'>
                <span>Request body</span>
                <span className='text text_assertive'>
                    {requestBody.required && 'required'}
                </span>
            </div>
        </div>
        <div className='text text_small'>
            Content-type: {requestBody.contentType}
        </div>
        <div className='row row_gap-5'>
            {(requestBody.value.multipart as MultiPart || []).map(({name, type, description}, index) =>
                <Param
                    key={index}
                    name={name}
                    type={type}
                    description={description}
                />
            )}
        </div>
    </>

    return (
        <div className='grid grid_rg-5'>
            {requestBody.contentType == 'multipart/form-data'
                ? renderMultiPart()
                : renderApplicationJSON()
            }
        </div>
    )
}
