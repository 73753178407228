import {Checkbox} from 'primereact/checkbox'

import {useFileSelectionMap} from '~/services/file'
import {toggleMapKey} from '~/utils/toggleMapKey'

import type {StorageFile} from '~/services/file'

type Props = {
    file: StorageFile
}

export default function({file}: Props) {
    const [selectionMap, setSelectionMap] = useFileSelectionMap()

    return (
        <Checkbox
            type='checkbox'
            checked={selectionMap.has(file.id)}
            onChange={() => setSelectionMap(toggleMapKey(selectionMap, file.id, file))}
        />
    )
}
