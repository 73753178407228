const bufferSize = 512
const channelCount = 1

export function createMicrophoneStream(sampleRate: number) {
    let context: AudioContext
    let streamSource: MediaStreamAudioSourceNode
    let processor: ScriptProcessorNode
    let innerController: ReadableStreamDefaultController<Float32Array>

    let resolver: () => void
    const initPromise = new Promise<void>(resolve => resolver = resolve)

    let isClosed = false

    const readableStream = new ReadableStream<Float32Array>({
        async start(controller) {
            context = new window.AudioContext({sampleRate})
            const mediaStream = await navigator.mediaDevices.getUserMedia({audio: true})
            streamSource = context.createMediaStreamSource(mediaStream)
            processor = context.createScriptProcessor(bufferSize, channelCount)

            innerController = controller

            streamSource.connect(processor)
            processor.connect(context.destination)

            processor.addEventListener(
                'audioprocess',
                event =>
                    context.state == 'running' &&
                    controller.enqueue(event.inputBuffer.getChannelData(0))
            )

            resolver()
        },
        cancel() {
            processor.disconnect()
            streamSource.disconnect()

            for (const track of streamSource.mediaStream.getTracks())
                track.stop()

            context.close()
        },
    })

    return Object.assign(readableStream, {close: () => {
        isClosed || initPromise.then(() => {
            processor.disconnect()
            streamSource.disconnect()

            for (const track of streamSource.mediaStream.getTracks())
                track.stop()

            context.close()
            innerController.close()

            isClosed = true
        })
    }})
}

export function convertFloat32ToInt16Array(input: Float32Array) {
    return Int16Array.from(input, sample => sample * 32767)
}
