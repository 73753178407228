export function saveFile(blobParts: BlobPart[], filename = '', type = 'text/plain') {
    const downloadLink = document.createElement('a')
    downloadLink.href = window.URL.createObjectURL(new Blob(blobParts, {type}))
    downloadLink.download = filename

    document.body.append(downloadLink)
    downloadLink.click()
    downloadLink.remove()
    URL.revokeObjectURL(downloadLink.href)
}
