import {ContextMenu as PrimeContextMenu} from 'primereact/contextmenu'
import React, {useRef} from 'react'

import {setContextMenuHider} from '~/services/contextMenu'

import type {ContextMenuProps as PrimeContextMenuProps} from 'primereact/contextmenu'
import type {MenuItem} from 'primereact/menuitem'

type ContextMenuOwnProps<T extends React.ElementType = React.ElementType> = {
    tag: T
    items: MenuItem[]
    menuProps?: PrimeContextMenuProps
    children: React.ReactNode
}

type ContextMenuProps<T extends React.ElementType> = ContextMenuOwnProps<T> &
    Omit<React.ComponentProps<T>, keyof ContextMenuOwnProps>

export default function ContextMenu<T extends React.ElementType>({
    tag,
    items,
    menuProps = {},
    children,
    ...props
}: ContextMenuProps<T>) {
    const element = useRef<PrimeContextMenu>(null)

    const contextComponent = React.createElement(
        tag,
        {
            onContextMenu: (event: React.MouseEvent) => {
                const menu = element.current

                if (!menu)
                    return

                setContextMenuHider(() => menu.hide(event))
                menu.show(event)
            },
            ...props,
        },
        children,
    )

    return (
        <>
            <PrimeContextMenu
                ref={element}
                model={items}
                {...menuProps}
            />
            {contextComponent}
        </>
    )
}
