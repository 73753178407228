import {Dropdown} from 'primereact/dropdown'
import {InputTextarea} from 'primereact/inputtextarea'
import {useEffect, useState} from 'react'

import Icon from './Icon'
import Progress from './Progress'
import OverallProgress from '~/components/OverallProgress'
import {trl, trlCount, trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {getVoices, pauseAudio, playAudio, resumeAudio, subscribeOnEvents, synthesize} from '~/services/synthesis'
import {useCurrentUser} from '~/services/user'
import {formatDate} from '~/utils/formatDate'
import {saveFile} from '~/utils/saveFile'
import {useObjectUrl} from '~/utils/useObjectUrl'

import type {DropdownProps} from 'primereact/dropdown'
import type {SoundStatus, Voice} from '~/services/synthesis'

const getDefaultText = (lang: 'ru' | 'kk' | undefined) => lang ? trlObject(`_synthesis.text.default.${lang}`).join('') : ''

const getSpeeds = () => Object.entries(trlObject('_synthesis.speeds'))
    .map(([value, label]) => ({
        value: +value,
        label,
    }))
    .sort((speed1, speed2) => speed2.value - speed1.value)

const getSampleRates = () => Object.entries(trlObject('_synthesis.sample-rate'))
    .map(([value, label]) => ({
        value: +value,
        label,
    }))
    .sort((option1, option2) => option2.value - option1.value)

const initialEmptyVoices: Voice[] = []

export default function() {
    const [text, setText] = useState('')
    const [voices, setVoices] = useState(initialEmptyVoices)
    const [voiceId, setVoiceId] = useState('')
    const [emotionOptions, setEmotionOptions] = useState<DropdownProps['options']>([])
    const [emotionId, setEmotionId] = useState('')
    const speeds = getSpeeds()
    const sampleRates = getSampleRates()
    const [speed, setSpeed] = useState(1)
    const [sampleRate, setSampleRate] = useState(sampleRates[0].value)
    const [ready, setReady] = useState(true)
    const [currentUser] = useCurrentUser()
    const [objectUrl, setBlob] = useObjectUrl()
    const [soundStatus, setSoundStatus] = useState<SoundStatus>('stopped')

    useEffect(
        () => {
            getVoices()
                .catch(showErrorPopup)
                .catch(() => [])
                .then((voices): Voice[] => voices.length == 0
                    ? [{
                        id: '',
                        name: trl('Нет доступных голосов'),
                        version: '',
                    }]
                    : voices
                )
                .then(voices => {
                    setVoices(voices)
                    setVoiceId(voices[0].id)
                    setText(getDefaultText(voices[0].language))
                })
        },
        [],
    )

    useEffect(
        () => subscribeOnEvents({
            ended: () => setSoundStatus('stopped'),
            play: () => setSoundStatus('playing'),
            pause: () => setSoundStatus(objectUrl ? 'paused' : 'stopped'),
        }),
        [objectUrl],
    )

    const handleClick = () => {
        switch (soundStatus) {
        case 'stopped':
            return objectUrl ? playAudio(objectUrl) : play()
        case 'playing':
            return pauseAudio()
        case 'paused':
            return resumeAudio()
        }
    }

    useEffect(
        () => {
            if (objectUrl)
                playAudio(objectUrl)
            else
                setSoundStatus('stopped')

            return pauseAudio
        },
        [objectUrl]
    )

    useEffect(
        () => setBlob(undefined),
        [text, voiceId, emotionId, speed, sampleRate, setBlob]
    )

    const play = () => {
        pauseAudio()
        setReady(false)

        synthesize(voiceId, text, speed, sampleRate, emotionId || undefined)
            .then(setBlob)
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    function download() {
        setReady(false)

        const filename = `${trl('_synthesis.filename', formatDate(new Date), text.slice(0, 20))}.wav`

        synthesize(voiceId, text, speed, sampleRate, emotionId || undefined)
            .then(blob => saveFile([blob], filename, 'audio/wav'))
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    const selectedVoiceTemplate = (option: Voice | undefined, props: DropdownProps) => {
        if (option)
            return (
                <div className='row row_gap-2'>
                    <div className={`flag flag-${option.language}`}/>
                    <div>{option.name}</div>
                </div>
            )

        return <span>{props.placeholder}</span>
    }

    const voiceOptionTemplate = (option: Voice) =>
        <div className='row row_gap-2'>
            <div className={`flag flag-${option.language}`}/>
            <div>{option.name}</div>
        </div>

    const getVoiceById = (id: string) => voices.find(voice => voice.id == id)

    const selectedVoice = getVoiceById(voiceId)

    useEffect(
        () => {
            if (!selectedVoice)
                return

            const emotions = selectedVoice.emotions || []

            if (!emotions.find(({name}) => name == emotionId))
                setEmotionId(emotions[0]?.name || '')

            setEmotionOptions(emotions.map(emotion => ({
                value: emotion.name,
                label: emotion.display_name,
            })))
        },
        [emotionId, selectedVoice],
    )

    if (voices == initialEmptyVoices)
        return (
            <div className='page__main row row_center'>
                <OverallProgress/>
            </div>
        )

    return <div className='page__main page__main_synthesis'>
        <div className='synthesis row row_col row_stretch'>
            <div className='synthesis__textarea'>
                <InputTextarea
                    className='synthesis__textarea'
                    value={text}
                    onChange={({target}) => setText(target.value)}
                    readOnly={!ready}
                />
                {text.length > (currentUser?.limits.tts_characters_per_request || 0) &&
                    <div className='error'>
                        {trlCount('Ограничение превышено: %0/%1 символов', text.length, currentUser?.limits.tts_characters_per_request || 0)}
                    </div>
                }
            </div>
            <div className='synthesis__control'>
                <div className='synthesis__wrapper'>
                    <div className='synthesis__wrapper-dropdowns'>
                        <div className='synthesis__control-select'>
                            <div className='row row_col'>
                                <label className='grid grid_rg-2'>
                                    <span>
                                        {trl('Голос синтеза')}
                                    </span>
                                    <Dropdown
                                        className='synthesis__dropdown'
                                        options={voices}
                                        optionLabel='name'
                                        optionValue='id'
                                        value={voiceId}
                                        onChange={({value}: {value: string}) => {
                                            setVoiceId(value)

                                            const [oldLanguage, newLanguage] = [voiceId, value]
                                                .map(getVoiceById)
                                                .map(voice => voice?.language)

                                            if (oldLanguage != newLanguage)
                                                setText(getDefaultText(newLanguage))
                                        }}
                                        valueTemplate={selectedVoiceTemplate}
                                        itemTemplate={voiceOptionTemplate}
                                        disabled={!ready || !voiceId}
                                    />
                                </label>
                            </div>
                        </div>
                        {selectedVoice?.emotions &&
                            <div className='synthesis__control-select'>
                                <div className='row row_col'>
                                    <label className='grid grid_rg-2'>
                                        <span>
                                            {trl('Эмоциональная окраска')}
                                        </span>
                                        <Dropdown
                                            className='synthesis__dropdown'
                                            options={emotionOptions}
                                            value={emotionId}
                                            onChange={({value}: {value: string}) => setEmotionId(value)}
                                            disabled={!ready}
                                        />
                                    </label>
                                </div>
                            </div>
                        }
                        <div className='synthesis__control-select'>
                            <div className='row row_col'>
                                <label className='grid grid_rg-2'>
                                    <span>
                                        {trl('Скорость воспроизведения')}
                                    </span>
                                    <Dropdown
                                        className='synthesis__dropdown'
                                        options={speeds}
                                        value={speed}
                                        onChange={({value}: {value: number}) => setSpeed(value)}
                                        disabled={!ready}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className='synthesis__control-select'>
                            <div className='row row_col'>
                                <label className='grid grid_rg-2'>
                                    <span>
                                        {trl('Частота дискретизации')}
                                    </span>
                                    <Dropdown
                                        className='synthesis__dropdown'
                                        options={sampleRates}
                                        value={sampleRate}
                                        onChange={({value}: {value: number}) => setSampleRate(value)}
                                        disabled={!ready}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='synthesis__buttons'>
                        {ready
                            ? <>
                                <button
                                    onClick={handleClick}
                                    disabled={!voiceId}
                                >
                                    <Icon
                                        className='synthesis__play-icon'
                                        name={soundStatus == 'playing' ? 'pause' : 'play'}
                                    />
                                </button>
                                <button
                                    onClick={download}
                                    className='synthesis__button'
                                    disabled={!voiceId}
                                >
                                    <i className='pi pi-download synthesis__icon-download'/>
                                </button>
                            </>
                            : <Progress/>
                        }
                    </div>
                </div>
            </div>

        </div>
    </div>
}
