import {ErrorCodes} from '~/services/error'
import {getTaskResult, searchTaskForFile} from '~/services/file'
import {trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {delay} from '~/utils/delay'
import {extractChat} from '~/utils/extractChat'
import {saveFile} from '~/utils/saveFile'

import type {StorageFile} from '~/services/file'

function getTranscription(file: StorageFile) {
    return searchTaskForFile(file.id)
        .then(({id}) => getTaskResult(id))
        .then(taskData => {
            if (taskData.status == 'error')
                throw {code: ErrorCodes.recognitionError}

            if (taskData.status != 'complete')
                throw {code: ErrorCodes.uncomplete}

            const text = extractChat(taskData)

            return {file, text}
        })
}

export function downloadTranscriptions(files: StorageFile[]) {
    Promise.all(files.map(file =>
        getTranscription(file)
            .catch(showErrorPopup)
            .catch(() => undefined)
    ))
        .then(transcriptResults =>
            transcriptResults
                .filter(Boolean)
                .forEach((result, index) => delay(300 * index)
                    .then(() => saveFile([result.text], `${trl('расшифровка %0', result.file.name)}.txt`))
                )
        )
        .catch(showErrorPopup)
}
