import {Button} from 'primereact/button'
import {Password} from 'primereact/password'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {trl} from '~/services/intl'
import {showErrorPopup, showPopup} from '~/services/popup'
import {completePasswordReset, useCurrentUser} from '~/services/user'
import {useSearchParams} from '~/utils/url'

import type {FormEvent} from 'react'

export default function() {
    const navigate = useNavigate()
    const [currentUser] = useCurrentUser()
    const {token} = useSearchParams({token: ''})
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [ready, setReady] = useState(true)

    useEffect(
        () => currentUser && navigate('/files', {replace: true}),
        [currentUser, navigate]
    )

    const submit = (event: FormEvent) => {
        event.preventDefault()

        setReady(false)

        completePasswordReset(token, password)
            .then(() => showPopup(trl('Пароль изменён')))
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    return (
        <div className='page__main'>
            <form className='register custom-controls-register'>
                <div className='row row_tall row_center register__row'>
                    <div className='col register__card'>
                        <div className='grid grid_rg-10'>
                            <span className='register__caption row row_center'>
                                {trl('Восстановление пароля')}
                            </span>
                            <span className='register__recover-title'>
                                {trl('Придумайте новый пароль')}
                            </span>
                            <div className='grid grid_rg-10 register__controls'>
                                <Password
                                    type='password'
                                    placeholder={trl('Новый пароль')}
                                    value={password}
                                    feedback={false}
                                    onChange={({target}) => setPassword(target.value)}
                                    toggleMask
                                />
                                <div className='row row_gap-2'>
                                    <Password
                                        className='row'
                                        type='password'
                                        placeholder={trl('Повторите пароль')}
                                        value={password2}
                                        feedback={false}
                                        onChange={({target}) => setPassword2(target.value)}
                                        toggleMask
                                    />
                                    {password2 != password &&
                                        <span className='text text_assertive text_small'>
                                            {trl('Пароли не совпадают')}
                                        </span>
                                    }
                                </div>
                                <div className='row row_center'>
                                    <Button
                                        className='landing-blue-button landing-blue-button_large-x'
                                        onClick={submit}
                                        disabled={!ready || !password || password != password2}
                                    >
                                        {trl('Восстановить')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
