import {saveFile} from './saveFile'

export function export2CSV(array: unknown[][], filename = 'export.csv') {
    const csvData = array
        .map(row => row
            .map(item => `${item}`.replaceAll('"', '""'))
            .map(escapedItem => `"${escapedItem}"`)
            .join(';')
        )
        .join('\r\n')

    saveFile(['\ufeff', csvData], filename, 'text/csv;charset=utf-8;')
}
