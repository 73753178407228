import clsx from 'clsx'
import {useMemo} from 'react'

import Icon from './Icon'
import {seekAudioDispatcher, useCurrentAudioTime} from '~/services/audio'
import {trl} from '~/services/intl'
import {getPhrases} from '~/utils/extractChat'
import {formatChatTime} from '~/utils/formatDate'
import {formatPercent} from '~/utils/formatPercent'

import type {AsrTaskResult} from '~/services/file'
import type {PhraseWord} from '~/utils/extractChat'

const wordHighlightTimeWidth = 1000

type ViewPhraseWord = PhraseWord & {
    title?: string
}

type Props = {
    taskResult: AsrTaskResult
}

export default function({taskResult}: Props) {
    const [time] = useCurrentAudioTime()

    const phrases = useMemo(
        () => {
            const phrases = getPhrases(taskResult)
                .phrases
                .map(phrase => ({
                    ...phrase,
                    words: phrase.words
                        .map(word => ({
                            ...word,
                            title: trl('Достоверность: %0', word.greedy_score),
                        })),
                }))
                .filter(({words}) => words.length)

            return phrases
        },
        [taskResult]
    )

    return <div className='chat'>
        <div className='chat__content'>
            {
                phrases.map((phrase, index) =>
                    <div
                        key={index}
                        className='row message-row'
                    >
                        <div className={clsx('message', phrase.channel != 0 && 'message_right')}>
                            <div className='message__title row'>
                                <div className='name'>
                                    {trl('Голос #%0', phrase.channel + 1)}
                                </div>
                                <div className='time'>
                                    {formatChatTime(phrase.begin)}
                                </div>
                            </div>
                            <div className='message__body'>
                                <span className='phrase'>
                                    {
                                        phrase.words.map((word: ViewPhraseWord, wordIndex) =>
                                            <span
                                                key={`${wordIndex}`}
                                                className={clsx(
                                                    'word',
                                                    Math.abs(word.begin - time) < wordHighlightTimeWidth && 'word_active'
                                                )}
                                                onClick={() => seekAudioDispatcher.dispatch(word.begin)}
                                                title={word.title}
                                            >
                                                {` ${word.word} `}
                                            </span>
                                        )
                                    }
                                </span>
                            </div>
                            {(phrase.sentiments?.[0]?.sentiment == 'negative' || phrase.sentiments?.[0]?.sentiment == 'positive') &&
                                <div
                                    title={formatPercent(phrase.sentiments[0].confidence)}
                                    className='message__tags'
                                >
                                    <Icon
                                        name={phrase.sentiments[0]?.sentiment == 'negative'
                                            ? 'sentiment-client-negative'
                                            : 'sentiment-client-positive'
                                        }
                                        className={clsx('message__tag', phrase.sentiments[0]?.sentiment == 'negative'
                                            ? 'message__tag_negative'
                                            : 'message__tag_positive'
                                        )}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        </div>
        <div className='end'>
            {trl('Конец записи')}
        </div>
    </div>
}
