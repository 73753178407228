import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {useEffect, useState} from 'react'

import {trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {resetPassword} from '~/services/user'
import {useSearchParams} from '~/utils/url'
import {validateEmail} from '~/utils/validateEmail'

import type {FormEvent} from 'react'

export default function() {
    const {username: originalUsername} = useSearchParams({username: ''})
    const [username, setUsername] = useState('')
    const [ready, setReady] = useState(true)
    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(
        () => setUsername(originalUsername),
        [originalUsername]
    )

    const submit = (event: FormEvent) => {
        event.preventDefault()

        setReady(false)

        resetPassword(username)
            .then(() => setIsSubmitted(true))
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    return (
        <div className='page__main'>
            <form className='register custom-controls-register'>
                <div className='row row_tall row_center register__row'>
                    <div className='col register__card'>
                        <div className='grid grid_rg-20'>
                            <span className='register__caption row row_center'>
                                {trl('Восстановление пароля')}
                            </span>
                            {isSubmitted
                                ? <div className='register__recover-title'>
                                    {trl('На указанный адрес направлены инструкции по изменению пароля')}
                                </div>
                                : <div className='grid grid_rg-10'>
                                    <div className='register__recover-title'>
                                        {trl('E-mail, указанный при регистрации')}
                                    </div>
                                    <label className='grid grid_rg-5 register__controls'>
                                        <InputText
                                            type='email'
                                            placeholder={trl('E-mail')}
                                            value={username}
                                            onChange={({target}) => setUsername(target.value)}
                                            autoFocus
                                        />
                                    </label>
                                    <div className='row row_center'>
                                        <Button
                                            className='landing-blue-button landing-blue-button_large-x'
                                            onClick={submit}
                                            disabled={!ready || !validateEmail(username)}
                                        >
                                            {trl('Восстановить')}
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
