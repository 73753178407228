const {format} = new Intl.NumberFormat(undefined, {minimumIntegerDigits: 2})

export function formatMinutes(seconds: number) {
    const minutes = Math.floor(seconds / 60)
    seconds = Math.floor(seconds) % 60

    let string = `${minutes}`

    if (seconds)
        string += `:${format(seconds)}`

    return string
}
