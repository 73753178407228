import {Suspense} from 'react'
import {Route, BrowserRouter as Router, Routes, Navigate} from 'react-router-dom'

// import Api from '~/components/Api'
import Admin from './Admin/Admin'
import {ApiPage} from '~/components/documentation/ApiPage'
import Documentation from '~/components/documentation/Documentation'
import DocumentationLoad from '~/components/documentation/DocumentationTopicLoad'
import FileManager from '~/components/file/FileManager'
import FileViewDialog from '~/components/file/FileViewDialog'
import Header from '~/components/Header'
import Landing from '~/components/landing/Landing'
import Login from '~/components/login/Login'
import Register from '~/components/login/Register'
import RegisterComplete from '~/components/login/RegisterComplete'
import ResetPassword from '~/components/login/ResetPassword'
import ResetPasswordComplete from '~/components/login/ResetPasswordComplete'
import ModalDialogs from '~/components/ModalDialogs'
import OverallProgress from '~/components/OverallProgress'
import Popups from '~/components/Popups'
import Synthesis from '~/components/Synthesis'
import {useCurrentUser} from '~/services/user'

export default function() {
    const [currentUser] = useCurrentUser()

    return (
        <div className='page'>
            <Router>
                <Header/>
                <Suspense fallback={<div className='page__main'><OverallProgress/></div>}>
                    <Routes>
                        <Route path='/' element={<Landing/>}/>
                        <Route path='/admin' element={currentUser?.role == 'admin' ? <Admin/> : <Navigate replace to='/'/>}/>
                        <Route path='/register' element={<Register/>}/>
                        <Route path='/register/complete' element={<RegisterComplete/>}/>
                        <Route path='/login' element={<Login/>}/>
                        <Route path='/reset-password' element={<ResetPassword/>}/>
                        <Route path='/reset-password/complete' element={<ResetPasswordComplete/>}/>
                        {/* <Route path='/api' element={<Api/>}/> */}
                        <Route path='/files' element={currentUser ? <FileManager/> : <Navigate replace to='/login'/>}>
                            <Route path=':id' element={<FileViewDialog/>}/>
                        </Route>
                        <Route path='/synthesis' element={currentUser ? <Synthesis/> : <Navigate replace to='/login'/>}/>
                        <Route path='/documentation' element={<Documentation/>}>
                            <Route path='' element={<Navigate replace to='recognition'/>}/>
                            <Route path='recognition' element={<Navigate replace to='/documentation/recognition-technology'/>}/>
                            <Route path='synthesis' element={<Navigate replace to='/documentation/synthesis-technology'/>}/>
                            <Route path='api' element={<Navigate replace to='/documentation/api-review'/>}/>
                            <Route path=':topic' element={<DocumentationLoad/>}/>
                            <Route path=':kind/:topic' element={<ApiPage/>}/>
                        </Route>
                        <Route path='/*' element={<Navigate replace to='/'/>}/>
                    </Routes>
                </Suspense>
                <ModalDialogs/>
                <Popups/>
            </Router>
        </div>
    )
}
