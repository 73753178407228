import {interfaceLanguage} from '~/services/intl'

export const formatDate = (date: Date, options?: Intl.DateTimeFormatOptions) =>
    date.toLocaleString(interfaceLanguage, options)

const {format} = new Intl.DateTimeFormat('ru', {timeStyle: 'medium', timeZone: 'UTC'})
export const formatChatTime = (millis: number) => format(new Date(millis))

const {format: monthFormatter} = new Intl.DateTimeFormat(interfaceLanguage, {month: 'long'})
export const formatMonth = (month: number) => monthFormatter(new Date(0).setMonth(month, 0))
