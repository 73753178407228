let contextMenuHider: () => void = Boolean

export function setContextMenuHider(hider: () => void) {
    hideContextMenu()
    contextMenuHider = hider
}

export function hideContextMenu() {
    contextMenuHider()
    contextMenuHider = Boolean
}

// TODO убрать когда починят hideOverlaysOnDocumentScrolling
window.addEventListener('scroll', hideContextMenu, {capture: true, passive: true})
