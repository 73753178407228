
import {trl, trlObject} from '~/services/intl'

export default function() {
    const {header, texts} = trlObject('_landing.boxes')['1']
    return (
        <div className='landing-box-1 row row_center row_col-center'>
            <div className='landing-box-1__content row row_col-center row_gap-14'>
                <h1 className='landing__header'>
                    {header}
                </h1>
                <p className='landing__text landing-box-1__text'>
                    {texts}
                </p>
                <a
                    className='p-button landing__button landing-blue-button landing-blue-button_large'
                    href='#suggestForm'
                >
                    {trl('Подать заявку')}
                </a>
            </div>
        </div>
    )
}
