import {useEffect, useMemo, useState} from 'react'

import FilterInput from './FilterInput'
import TableHeader from './TableHeader'
import UserHistory from './UserHistory'
import UserRow from './UserRow'
import UserSettingsDialog from './UserSettingsDialog'
import Pagination from '../Pagination'
import {trl, trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {getUsers, usersDispatcher} from '~/services/user'
import {export2CSV} from '~/utils/export2CSV'
import {formatDate} from '~/utils/formatDate'
import {urlDecode} from '~/utils/sort'
import {useSearchParams} from '~/utils/url'

import type {User} from '~/services/user'

const usersPerPage = 20
const exportLimit = 10 ** 7

export default function() {
    const [users, setUsers] = useState<User[]>([])
    const [usersTotal, setUsersTotal] = useState(0)
    const [ready, setReady] = useState(true)
    const [userSettingsDialog, setUserSettingsDialog] = useState<User>()
    const [userHistoryDialog, setUserHistoryDialog] = useState<User>()
    const searchParams = useSearchParams({sort: '', page: '1', filter: ''})
    const page = +searchParams.page
    const sort = useMemo(() => urlDecode(searchParams.sort), [searchParams.sort])
    const csvHeader = trlObject('аккаунты.экспорт.столбцы')

    useEffect(
        () => {
            const refresh = () =>
                getUsers({
                    limit: usersPerPage,
                    offset: (page - 1) * usersPerPage,
                    sort,
                    filter: searchParams.filter,
                })
                    .then(({users, total}) => {
                        setUsers(users)
                        setUsersTotal(total)
                    })
                    .catch(showErrorPopup)

            refresh()

            return usersDispatcher.subscribe(refresh)
        },
        [page, searchParams, sort]
    )

    const generateFilename = () => `${trl('аккаунты.экспорт.файл.имя', formatDate(new Date))}.csv`

    const prepareUserList = (users: User[]) => [
        csvHeader,
        ...users.map(user => [
            user.username,
            user.role,
            user.status,
            user.usage.seconds / 60,
            user.usage.storage_capacity,
            user.usage.task_count,
        ]),
    ]

    const exportUsers = () => {
        setReady(false)
        getUsers({limit: exportLimit})
            .then(({users}) => users)
            .then(prepareUserList)
            .then(users => export2CSV(users, generateFilename()))
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    return (
        <div className='page__main page__main_files'>
            <div className='admin'>
                <div className='row admin__header'>
                    <div className='row row_between'>
                        <FilterInput/>
                        <button
                            className='icon icon_download'
                            onClick={() => exportUsers()}
                            disabled={!ready}
                            title={trl('Скачать CSV')}
                        >
                            <i className='pi pi-download export-icon'/>
                        </button>
                    </div>
                    <TableHeader sort={sort}/>
                </div>
                {users.map(user =>
                    <UserRow
                        key={user.id}
                        user={user}
                        setUserSettingsDialog={setUserSettingsDialog}
                        setUserHistoryDialog={setUserHistoryDialog}
                    />
                )}
                <div className='admin-footer'>
                    <Pagination itemsTotal={usersTotal} itemsPerPage={usersPerPage}/>
                </div>
            </div>
            <UserSettingsDialog
                visible={!!userSettingsDialog}
                user={userSettingsDialog}
                onHide={() => setUserSettingsDialog(undefined)}
            />
            <UserHistory
                visible={!!userHistoryDialog}
                user={userHistoryDialog}
                onHide={() => setUserHistoryDialog(undefined)}
            />
        </div>
    )
}
