export class PeekIdentityTranform<T> extends TransformStream<T, T> {
    constructor(peek: (value: T) => void) {
        super({
            transform: (value, controller) => {
                peek(value)
                controller.enqueue(value)
            },
        })
    }
}

export class MapTransform<T, U> extends TransformStream<T, U> {
    constructor(mapper: (value: T) => U) {
        super({transform: (value, controller) => controller.enqueue(mapper(value))})
    }
}
