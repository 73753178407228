
import clsx from 'clsx'
import {Fragment, useState} from 'react'

import {trl, trlObject} from '~/services/intl'

export default function() {
    const {blocks, header} = trlObject('_landing.boxes')['9']
    const blockTitles = Object.keys(blocks) as (keyof typeof blocks)[]
    const [blockTitle, setBlockTitle] = useState(blockTitles[0])
    const {synthesis, recognition} = blocks

    const RecognitionComponent = () =>
        <div className='row row_col row_gap-8'>
            <div className='landing-box-9__card row row_col row_gap-10'>
                {/* <div className='landing-box-9__card-title row text'>{recognition.title}</div> */}
                <div className='landing-box-9__card-title row text'>
                    {recognition.subTitle}
                </div>
                <div className='landing-box-9__card-content row row_top row_nowrap'>
                    <div className='grid grid_col-2 grid_rg-10'>
                        {recognition.costs.map((cost, index) => <Fragment key={index}>
                            <span className='landing-box-9__card-cost-title landing__text text text_wrap'>{cost[0]}</span>
                            <span className='landing-box-9__card-cost-row row row_col row_center'>
                                <span className='landing__text'>{cost[1]}</span>
                                <span className='landing__text'>{cost[2]}</span>
                            </span>
                        </Fragment>)}
                    </div>
                    <div className='row row_col'>
                        <ul className='list list_small'>
                            {recognition.additional.map((text, index) =>
                                <li key={index}>
                                    <small className='landing-box-9__card_small text text_wrap'>
                                        {text}
                                    </small>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='landing-box-9__footer-cards row row_nowrap row_gap-8'>
                <div className='landing-box-9__footer-card row row_nowrap row_col row_between row_gap-6'>
                    <div className='landing-box-9__card-title row text'>
                        {recognition.footer.left}
                    </div>
                    <a
                        className='p-button p-button-outlined landing-blue-outlined-button'
                        href='#suggestForm'
                    >
                        {trl('По запросу')}
                    </a>
                </div>
                <div className='landing-box-9__footer-card landing-box-9__footer-card_gray row_nowrap row row_col row_between row_gap-6'>
                    <div className='row row_col row_gap-6'>
                        <ul className='list list_white'>
                            {recognition.footer.right.map((text, index) =>
                                <li key={index}>
                                    <span className='landing-box-9__footer-card-text'>
                                        {text}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <a
                        className='p-button p-button-outlined landing-white-outlined-button'
                        href='#suggestForm'
                    >
                        {trl('По запросу')}
                    </a>
                </div>
            </div>
        </div>

    const SynthesisComponent = () =>
        <div className='row row_col row_gap-8'>
            <div className='landing-box-9__card row row_col row_gap-10'>
                {/* <div className='landing-box-9__card-title row text'>{synthesis.title}</div> */}
                <div className='landing-box-9__card-title row text'>
                    {synthesis.subTitle}
                </div>
                <div className='landing-box-9__card-synthesis-costs '>
                    <div className='row row_col row_gap-6'>
                        <ul className='list'>
                            {synthesis.costs.map((cost, index) =>
                                <li key={index}>
                                    <span className='landing__text'>
                                        {cost}
                                    </span>
                                </li>
                            )}
                        </ul>
                        <span className='landing__text'>
                            {synthesis.note}
                        </span>
                    </div>
                </div>
            </div>
            <div className='landing-box-9__footer-cards row row_nowrap row_gap-8'>
                <div className='landing-box-9__footer-card row_nowrap row row_col row_between row_gap-6'>
                    <div className='landing-box-9__card-title row landing__text'>
                        {recognition.footer.left}
                    </div>
                    <a
                        className='p-button p-button-outlined landing-blue-outlined-button'
                        href='#suggestForm'
                    >
                        {trl('По запросу')}
                    </a>
                </div>
                <div className='landing-box-9__footer-card landing-box-9__footer-card_gray row_nowrap row row_col row_between row_gap-6'>
                    <div className='row row_col row_gap-6'>
                        <span className='landing-box-9__card-title landing-box-9__card-white-title'>
                            {synthesis.footer.right[0]}
                        </span>
                        <span className='landing-box-9__footer-card-text-small'>
                            {synthesis.footer.right[1]}
                        </span>
                    </div>
                    <a
                        className='p-button p-button-outlined landing-white-outlined-button'
                        href='#suggestForm'
                    >
                        {trl('По запросу')}
                    </a>
                </div>
            </div>
        </div>

    return (
        <div id='tariff' className='landing-box-9 row row_col-center row_gap-14'>
            <h1 className='landing__header'>
                {header}
            </h1>
            <div className='grid grid_col-2 grid_cg-0 grid_fit-content'>
                {blockTitles.map((title, index) =>
                    <button
                        key={index}
                        className={clsx('landing__link landing-box-9__link', title == blockTitle && 'landing__link_active')}
                        onClick={() => setBlockTitle(title)}
                    >
                        {blocks[title].title}
                    </button>
                )}
            </div>
            <div className='row row_col'>
                {blockTitle == 'recognition' && <RecognitionComponent/>}
                {blockTitle == 'synthesis' && <SynthesisComponent/>}
            </div>
        </div>
    )
}
