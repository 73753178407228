import {BACKEND} from '~/config'
import {PAGINATION_SIZE} from '~/constants'
import {handleHttpError} from '~/services/error'
import {getUseSharedState} from '~/utils/getUseSharedState'
import {http} from '~/utils/http'
import {urlEncode} from '~/utils/sort'
import {getSearchString} from '~/utils/url'

import type {Sort} from '~/utils/sort'

export type UserRole = 'admin' | 'user'

export type Limits = {
    storage_capacity: number
    seconds: number
    task_ttl: number
    task_count: number
    tts_characters_per_request: number
    tts_requests_per_second: number
    speaker_models: number
}

export type Usage = {
    storage_capacity: number
    seconds: number
    task_count: number
}

export type UsageHistoryItem = {
    year: number
    month: number
    seconds: number
}

export type User = {
    id: string
    username: string
    role: UserRole
    limits: Limits
    usage: Usage
    usage_history: UsageHistoryItem[]
    created_date: string
    status: 'active' | 'deleted'
}

export const currentUserChangeDispatcher = getUseSharedState({})

export const useCurrentUser = getUseSharedState<User | undefined>(undefined)

currentUserChangeDispatcher.subscribe(getCurrentUser)

export function getCurrentUser() {
    return http.get<User>(`${BACKEND.api}/profile`)
        .catch(() => undefined)
        .then(user => {
            useCurrentUser.dispatch(user)
            return user
        })
}

export function login(username: string, password: string, rememberMe?: boolean) {
    return http.post(`${BACKEND.auth}/login`, {username, password, rememberMe})
        .catch(handleHttpError)
        .finally(() => currentUserChangeDispatcher.dispatch({}))
}

export function logout() {
    return http.post(`${BACKEND.auth}/logout`)
        .catch(handleHttpError)
        .finally(() => currentUserChangeDispatcher.dispatch({}))
}

export function resetPassword(username: string) {
    const {protocol, host} = window.location

    return http.post(`${BACKEND.auth}/password`, {
        username,
        token_url: `${protocol}//${host}/reset-password/complete${getSearchString({token: ''})}`,
    })
        .catch(handleHttpError)
}

export function completePasswordReset(token: string, password: string) {
    return http.post(`${BACKEND.auth}/password/complete`, {token, password})
        .catch(handleHttpError)
        .finally(() => currentUserChangeDispatcher.dispatch({}))
}

export function register(username: string, password: string, displayName: string, company: string) {
    const {protocol, host} = window.location

    return http.post(`${BACKEND.auth}/register`, {
        username,
        password,
        display_name: displayName,
        settings: {
            company,
        },
        token_url: `${protocol}//${host}/register/complete${getSearchString({token: ''})}`,
    })
        .catch(handleHttpError)
}

export function completeRegister(token: string) {
    return http.post(`${BACKEND.auth}/register/complete`, {token})
        .catch(handleHttpError)
        .finally(() => currentUserChangeDispatcher.dispatch({}))
}

export const defaultFeedbackData = {
    name: '',
    email: '',
    company: '',
    phone: '',
    deliveryType: '',
    suggest: '',
    personalData: false,
    advertisements: false,
}

export function postFeedback(feedback: typeof defaultFeedbackData) {
    return http.post<{response: string}>(`${BACKEND.auth}/feedback`, feedback, {tag: 'vox'})
        .then(({response}) => response)
        .catch(handleHttpError)
}

export type ClientData = {
    client_id: string
    client_secret: string
}

export function getClientData() {
    return http.get<ClientData>(`${BACKEND.api}/client`)
        .catch(handleHttpError)
}

export function createClientData() {
    return http.put<ClientData>(`${BACKEND.api}/client`)
        .catch(handleHttpError)
}

type UserRequest = {
    offset?: number
    limit?: number
    filter?: string
    sort?: Sort
}

export const usersDispatcher = getUseSharedState([])

export function getUsers({offset = 0, limit, sort, filter}: UserRequest) {
    return http.get<{data: User[]}>(
        `${BACKEND.api}/admin/users`,
        {
            offset,
            limit: limit ? limit * (PAGINATION_SIZE - 1) + 1 : undefined,
            sort: sort ? urlEncode(sort) : undefined,
            username: filter ? `%${filter}%` : undefined,
        }
    )
        .then(({data}) => ({
            users: data.slice(0, limit),
            total: offset + data.length,
        }))
        .catch(handleHttpError)
}

export function updateUserLimit(userId: string, limits: Limits) {
    return http.put(`${BACKEND.api}/admin/users/${userId}/limits`, limits)
        .then(() => usersDispatcher.dispatch([]))
        .catch(handleHttpError)
}

