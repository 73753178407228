import clsx from 'clsx'

import Icon from './Icon'

import type {ComponentProps} from 'react'
import type {AsrTask} from '~/services/file'

type Props = Omit<ComponentProps<typeof Icon>, 'name'> & {
    status: AsrTask['status']
}

export default function({status, ...props}: Props) {
    if (status == 'complete')
        return null

    return (
        <Icon
            {...props}
            name={status == 'process' ? 'progress' : 'error'}
            className={clsx(`task-status task-status_${status}`, props.className)}
        />
    )
}
