export class Semaphore {
    private limit: number
    private readonly queue: (() => void)[] = []

    constructor(limit: number) {
        this.limit = limit
    }

    acquire() {
        const promise = new Promise<void>(resolve => this.queue.push(resolve))

        if (this.limit > 0)
            this.runNextTask()

        return promise
    }

    private runNextTask() {
        const resolver = this.queue.shift()

        if (!resolver)
            return

        this.limit--
        resolver()
    }

    release() {
        this.limit++
        this.runNextTask()
    }
}
