import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {Password} from 'primereact/password'
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import {trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {login, useCurrentUser} from '~/services/user'
import {getSearchString} from '~/utils/url'

import type {FormEvent} from 'react'

export default function() {
    const navigate = useNavigate()
    const [currentUser] = useCurrentUser()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [ready, setReady] = useState(true)

    useEffect(
        () => currentUser && navigate('/files', {replace: true}),
        [currentUser, navigate]
    )

    const submit = (event: FormEvent) => {
        event.preventDefault()

        setReady(false)

        login(username, password)
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    return (
        <div className='page__main'>
            <form className='register custom-controls-register'>
                <div className='row row_tall row_center register__row'>
                    <div className='col register__card'>
                        <div className='grid grid_rg-20'>
                            <span className='register__caption row row_center'>
                                {trl('Вход')}
                            </span>
                            <div className='grid grid_rg-10 register__controls'>
                                <InputText
                                    placeholder={trl('E-mail')}
                                    value={username}
                                    onChange={({target}) => setUsername(target.value)}
                                    autoFocus
                                />
                                <div className='grid grid_rg-4'>
                                    <label className='grid grid_rg-2'>
                                        <Password
                                            placeholder={trl('Пароль')}
                                            value={password}
                                            feedback={false}
                                            onChange={({target}) => setPassword(target.value)}
                                        />
                                    </label>
                                    <div className='row row_right'>
                                        <Link
                                            className='register__recover'
                                            to={{
                                                pathname: '/reset-password',
                                                search: getSearchString({username}),
                                            }}
                                        >
                                            {trl('Забыли пароль?')}
                                        </Link>
                                    </div>
                                </div>
                                <div className='row row_center'>
                                    <Button
                                        className='landing-blue-button landing-blue-button_large-x'
                                        onClick={submit}
                                        disabled={!ready || !username || !password}
                                    >
                                        {trl('Войти')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
