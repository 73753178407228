import React, {useEffect, useMemo, useRef} from 'react'

import type {JSX} from 'react'

type OwnProps<T extends keyof JSX.IntrinsicElements> = {
    tag: T
    children?: React.ReactNode
}

type Props<T extends keyof JSX.IntrinsicElements> = OwnProps<T> & Omit<React.ComponentProps<T>, keyof OwnProps<T>>

export default function<T extends keyof JSX.IntrinsicElements>({tag, children, ...props}: Props<T>) {
    const ref = useRef<Element>()

    const Component = useMemo(
        () => React.createElement(tag, {ref, ...props}, children),
        [children, props, tag]
    )

    useEffect(
        () => {
            const element = ref.current

            if (!element)
                return

            const observer = new MutationObserver(() => element.scrollTop = element.scrollHeight)

            observer.observe(element, {
                attributes: true,
                characterData: true,
                childList: true,
                subtree: true,
            })

            return () => observer.disconnect()
        },
        []
    )

    return Component
}
