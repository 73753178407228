import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {showErrorPopup} from '~/services/popup'
import {completeRegister, useCurrentUser} from '~/services/user'
import {useSearchParams} from '~/utils/url'

export default function() {
    const navigate = useNavigate()
    const [currentUser] = useCurrentUser()
    const {token} = useSearchParams({token: ''})
    const [ready, setReady] = useState(false)

    useEffect(
        () => currentUser && navigate('/', {replace: true}),
        [currentUser, navigate]
    )

    completeRegister(token)
        .catch(showErrorPopup)
        .finally(() => setReady(true))

    return <>
        {ready || <progress/>}
    </>
}
