import {Checkbox} from 'primereact/checkbox'
import {useMemo} from 'react'

import WithMenu from '~/components/WithMenu'
import {confirm} from '~/services/confirmationDialog'
import {
    createTask,
    deleteFiles, deleteTask,
    downloadFiles,
    searchTaskForFileBatch,
    useFileSelectionMap, useTaskChangeDispatcher,
} from '~/services/file'
import {trl, trlCount} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {toggleMapKeyMutable} from '~/utils/toggleMapKey'
import {downloadTranscriptions} from '~/utils/transcript'

import type {StorageFile} from '~/services/file'

type Props = {
    files: StorageFile[]
}

export default function({files}: Props) {
    const [selectionMap, setSelectionMap] = useFileSelectionMap()

    const allSelected = useMemo(
        () => files.every(({id}) => selectionMap.has(id)),
        [files, selectionMap]
    )

    const togglePageFiles = () => {
        const resultMap = new Map(selectionMap)

        for (const file of files)
            toggleMapKeyMutable(resultMap, file.id, file, !allSelected)

        setSelectionMap(resultMap)
    }

    const menuItems = [
        {
            label: trl('Скачать'),
            command: () => downloadFiles([...selectionMap.values()]),
        },
        {
            label: trl('Скачать расшифровку'),
            command: () => downloadTranscriptions([...selectionMap.values()]),
        },
        {
            label: trl('Переобработать'),
            command: () => searchTaskForFileBatch([...selectionMap.values()].map(file => file.id))
                .catch(() => undefined)
                .then(tasks => tasks &&
                    Promise.all(tasks.map(task => deleteTask(task.id)))
                        .then(() => Promise.all(tasks.map(task => createTask(task.file_id))))
                )
                .catch(showErrorPopup)
                .finally(() => useTaskChangeDispatcher.dispatch({})),
        },
        {
            label: trl('Удалить'),
            command: () => confirm(
                trl('Удалить?'),
                trlCount('Будут удалены %0 файлов', selectionMap.size),
                trl('Удалить'),
            )
                .then(() => deleteFiles([...selectionMap.keys()]))
                .catch(showErrorPopup),
        },
        {
            label: trl('Снять выделение'),
            command: () => setSelectionMap(new Map),
        },
    ]

    return <div className='row row_fit row_gap-2 row_nowrap file-chooser'>
        <label className='row row_gap-5'>
            <Checkbox
                type='checkbox'
                checked={allSelected}
                onChange={togglePageFiles}
            />
            <span className='file-chooser-counter'>
                {trlCount('Выбраны %0 файлов', selectionMap.size)}
            </span>
        </label>
        {selectionMap.size > 0 &&
            <WithMenu items={menuItems}>
                <i className='pi pi-ellipsis-v file-icon-chooser'/>
            </WithMenu>
        }
    </div>
}
