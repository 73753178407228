import clsx from 'clsx'

import type {ComponentProps} from 'react'

type Props = ComponentProps<'div'> & {
    samples: Float32Array
}

export default function({samples, ...props}: Props) {
    return (
        <div
            {...props}
            className={clsx('microphone-indicator', props.className)}
            style={{
                ...props.style,
                '--value': samples.reduce((result, sample) => Math.max(result, Math.abs(sample)), 0),
            }}
        />
    )
}
