export const BACKEND = process.env.NODE_ENV == 'production'
    ? {
        api: `${location.protocol}//${location.host}/backend`,
        auth: `${location.protocol}//${location.host}/auth`,
        websocket: `${location.protocol == 'https:' ? 'wss:' : 'ws:'}//${location.host}/backend`,
        swagger: `${location.protocol}//${location.host}/swagger`,
        audioUrlPrefix: '',
    }
    : {
        // api: 'https://3i-vox.ru/backend',
        // auth: 'https://3i-vox.ru/auth',
        // websocket: 'wss://3i-vox.ru/backend',
        // swagger: 'https://3i-vox.ru/swagger',
        // audioUrlPrefix: 'https://3i-vox.ru',

        api: 'https://3i-vox.xyz/backend',
        auth: 'https://3i-vox.xyz/auth',
        websocket: 'wss://3i-vox.xyz/backend',
        swagger: 'https://3i-vox.xyz/swagger',
        audioUrlPrefix: 'https://3i-vox.xyz',
    }
