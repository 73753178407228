import {MultiSelect} from 'primereact/multiselect'
import {useEffect, useState} from 'react'

import {getModels} from '~/services/asr'
import {trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'

import type {DropdownProps} from 'primereact/dropdown'
import type {MultiSelectProps} from 'primereact/multiselect'

type Props = Omit<MultiSelectProps, 'onChange' | 'value'> & {
    modelIds?: string[]
    onChange: (modelIds: string[]) => void
}

export default function({modelIds = [], onChange, ...props}: Props) {
    const [options, setOptions] = useState<DropdownProps['options']>([])

    useEffect(
        () => {
            getModels()
                .then(models => models.map(model => ({
                    value: model.model_id,
                    label: model.model_id,
                })))
                .then(setOptions)
                .catch(showErrorPopup)
        },
        []
    )

    return (
        <label className='grid grid_rg-2'>
            <span>
                {trl('Модель')}
            </span>
            <MultiSelect
                {...props}
                filter
                options={options}
                value={modelIds}
                appendTo={undefined}
                onChange={({value}: {value: string[]}) => onChange(value)}
            />
        </label>
    )
}
