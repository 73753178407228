import {useEffect, useRef} from 'react'

import {BACKEND} from '~/config'
import {seekAudioDispatcher, useCurrentAudioTime} from '~/services/audio'
import {ErrorCodes} from '~/services/error'
import {showErrorPopup} from '~/services/popup'

import type {ComponentProps} from 'react'
import type {StorageFile} from '~/services/file'

type Props = ComponentProps<'audio'> & {
    file: StorageFile
}

export default function({file, ...props}: Props) {
    const ref = useRef<HTMLAudioElement>(null)
    useEffect(() => useCurrentAudioTime.dispatch(0), [file.link])

    const handleError = () =>
        showErrorPopup({code: ErrorCodes.audioError})

    useEffect(
        () => seekAudioDispatcher.subscribe(time => {
            if (ref.current)
                ref.current.currentTime = time / 1000
        }),
        []
    )

    return (
        <audio
            ref={ref}
            className='row'
            {...props}
            onTimeUpdate={event => useCurrentAudioTime.dispatch(event.currentTarget.currentTime * 1000)}
            src={`${BACKEND.audioUrlPrefix}${file.link}`}
            onError={handleError}
            controls
            controlsList='nodownload'
        />
    )
}
