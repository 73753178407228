import {useEffect, useState} from 'react'

import {formatChatTime} from '~/utils/formatDate'

type Props = {
    seconds: number
    onElapse?: () => void
}

export default function({seconds, onElapse = Boolean}: Props) {
    const [currentSeconds, setCurrentSeconds] = useState(0)
    const [elapsed, setElapsed] = useState(false)

    useEffect(
        () => {
            const intervalId = window.setInterval(
                () => setCurrentSeconds(currentSeconds => Math.min(currentSeconds + 1, seconds)),
                1000
            )

            return () => window.clearInterval(intervalId)
        },
        [seconds]
    )

    useEffect(
        () => {
            if (currentSeconds == seconds && !elapsed) {
                onElapse()
                setElapsed(true)
            }
        },
        [currentSeconds, elapsed, onElapse, seconds]
    )

    return <>
        {formatChatTime(currentSeconds * 1000)} / {formatChatTime(seconds * 1000)}
    </>
}
