
import clsx from 'clsx'
import {useState} from 'react'

import {trlObject} from '~/services/intl'

export default function() {
    const {blocks, header, subtitle} = trlObject('_landing.boxes')['5']
    const blockTitles = blocks.map(({title}) => title)
    const [blockIndex, setBlockIndex] = useState(0)

    const SpeechSynthesisBlock = () =>
        <div className='landing-box-5__speech-synthesis-block'>
            {blocks[0].texts.map((text, index) =>
                <span
                    key={index}
                    className={clsx('landing-box-5__card', !index && 'text text_wrap')}
                >
                    {!index &&
                        <ul className='list'>
                            {[...text].map((item, index) =>
                                <li key={index}>
                                    <span className='landing__text'>
                                        {item}
                                    </span>
                                </li>
                            )}
                        </ul>
                    }
                    {!!index &&
                        <span className='landing__text'>
                            {text}
                        </span>
                    }
                </span>
            )}
        </div>

    return (
        <div className='landing-box-5 row row_nowrap row_col-center'>
            <h1 className='landing__header'>
                {header}
            </h1>
            <small className='landing-box-5__subtitle landing__subtitle'>{subtitle}</small>
            <div className='landing-box-5__links grid grid_col-3 grid_cg-0'>
                {blockTitles.map((title, index) =>
                    <button
                        key={index}
                        className={clsx('landing__link', index == blockIndex && 'landing__link_active')}
                        onClick={() => setBlockIndex(index)}
                    >
                        {title}
                    </button>
                )}
            </div>
            {blockIndex === 0 &&
                <SpeechSynthesisBlock/>
            }
            {blockIndex !== 0 &&
                <div className='landing-box-5__blocks'>
                    {blocks[blockIndex].texts.map((text, index) =>
                        <span
                            key={index}
                            className='landing-box-5__card'
                        >
                            <span className='landing__text'>
                                {text}
                            </span>
                        </span>
                    )}
                </div>
            }
        </div>
    )
}

