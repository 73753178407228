import clsx from 'clsx'
import {useState} from 'react'

import LandingRecognition from './LandingRecognition'
import LandingSynthesis from './LandingSynthesis'
import {trlObject} from '~/services/intl'

export default function() {
    const {blocks, header} = trlObject('_landing.boxes')['4']
    const blockTitles = Object.keys(blocks) as (keyof typeof blocks)[]
    const [blockTitle, setBlockTitle] = useState(blockTitles[0])

    return (
        <div className='landing-box-4 row row_nowrap row_col-center row_gap-14'>
            <h1 className='landing__header'>
                {header}
            </h1>
            <div className='landing-box-4__links grid grid_col-2 grid_cg-0'>
                {blockTitles.map((title, index) =>
                    <button
                        key={index}
                        className={clsx('landing__link', title == blockTitle && 'landing__link_active')}
                        onClick={() => setBlockTitle(title)}
                    >
                        {blocks[title].title}
                    </button>
                )}
            </div>
            {blockTitle == 'speechRecognition' && <LandingRecognition/>}
            {blockTitle == 'speechSynthesis' && <LandingSynthesis/>}
        </div>
    )
}
