import ConfirmDialog from '~/components/ConfirmDialog'
import PromptDialog from '~/components/PromptDialog'
import {ErrorCodes} from '~/services/error'
import {getUseSharedState} from '~/utils/getUseSharedState'

import type {BackendError} from '~/services/error'

export interface ConfirmProps {
    header: string
    message: string
    actionLabel: string
    danger?: boolean
    onDone: (confirmed: boolean) => void
}

export interface PromptProps extends Omit<ConfirmProps, 'onDone'> {
    id: number
    ready: boolean
    initialValue: string
    inputFieldProps: {
        type?: string
        min?: number
        max?: number
        step?: number
        placeholder?: string
        required?: boolean
        pattern?: string
    }
    validate: (value: string) => boolean
    cancel: () => void
    onDone: (value: string, setReady: (ready: boolean) => void) => void
}

interface ConfirmDialogData {
    id: number
    Component: React.ElementType
    props: ConfirmProps
}

interface PromptDialogData extends Omit<ConfirmDialogData, 'props'> {
    props: PromptProps
    setReady: (ready: boolean) => void
    action: <T>(value: string) => Promise<T>
    errorHandler: (error: BackendError) => void
    open: () => Promise<void>
    close: () => void
}

type DialogData = ConfirmDialogData | PromptDialogData

export const useDialogs = getUseSharedState<DialogData[]>([])

let id = 0

export function confirm(header: string, message: string, actionLabel: string, danger?: boolean) {
    return new Promise<void>((resolve, reject) => {
        const dialogId = id++
        addDialog({
            id: dialogId,
            Component: ConfirmDialog,
            props: {
                header,
                message,
                actionLabel,
                danger,
                onDone: (confirmed: boolean) => {
                    removeDialog(dialogId)

                    if (confirmed)
                        resolve()
                    else
                        reject({code: ErrorCodes.userCancel})
                },
            },

        })
    })
}

export const dialogReadyChangeDispatcher = getUseSharedState<Record<number, boolean>>({})

export function prompt<T>(
    header: string,
    message: string,
    actionLabel: string,
    action: (value: string) => Promise<T>,
    errorHandler: (error: BackendError) => never,
    initialValue = '',
    inputFieldProps: PromptProps['inputFieldProps'] = {},
    validate?: (value: string) => boolean,
) {
    const dialogId = id++

    const dialogData = {
        id: dialogId,
        Component: PromptDialog,
        props: {
            id: dialogId,
            header,
            message,
            actionLabel,
            ready: true,
            inputFieldProps,
            initialValue,
            validate: value => !validate || validate(value),
        },
        setReady: ready => {
            const readyState = dialogReadyChangeDispatcher.getState()
            dialogReadyChangeDispatcher.dispatch({...readyState, [dialogId]: ready})
        },
    } as PromptDialogData

    addDialog(dialogData)

    return new Promise<T>((resolve, reject) => {
        dialogData.props.onDone = (string, setReady) => {
            setReady(false)
            action(string)
                .then(response => {
                    resolve(response)
                    dialogData.close()
                })
                .catch(errorHandler)
                .finally(() => setReady(true))
        }

        dialogData.close = () => {
            removeDialog(dialogId)

            const readyState = {...dialogReadyChangeDispatcher.getState()}
            delete readyState[dialogId]
            dialogReadyChangeDispatcher.dispatch(readyState)

            reject({code: ErrorCodes.userCancel})
        }

        dialogData.props.cancel = dialogData.close
    })
        .catch(errorHandler)
}

const addDialog = (dialogData: DialogData) =>
    useDialogs.dispatch([...useDialogs.getState(), dialogData])

const removeDialog = (id: number) =>
    useDialogs.dispatch(useDialogs.getState().filter(dialog => dialog.id != id))
