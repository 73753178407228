import {Accordion, AccordionTab} from 'primereact/accordion'
import {Fragment} from 'react'

import {trl, trlObject} from '~/services/intl'

export default function() {
    const {header, blocks} = trlObject('_landing.boxes')['7']
    return (
        <div className='landing-box-7 row row_col-center row_gap-14'>
            <h1 className='landing__header '>
                {header}
            </h1>
            <Accordion className='landing-box-7__accordeon' activeIndex={0}>
                {blocks.map(({title, texts}, i) =>
                    <AccordionTab key={i} header={title}>
                        {texts.map((text, j) => {
                            if (i == 7)
                                return <Fragment key={`${i}-${j}`}>
                                    <p>
                                        {texts[0]}
                                    </p>
                                    <br/>
                                    <p>
                                        <a
                                            className='landing__link landing__link_default-size'
                                            href='#suggestForm'
                                        >
                                            {trl('Оставьте заявку')}
                                        </a>
                                        , {trl('мы с вам свяжемся')}
                                    </p>
                                </Fragment>

                            return <Fragment key={`${i}-${j}`}>
                                <p>{text}</p>
                                <br/>
                            </Fragment>
                        })}
                    </AccordionTab>
                )}
            </Accordion>
        </div>
    )
}
