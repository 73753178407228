import {BACKEND} from '~/config'
import {PAGINATION_SIZE} from '~/constants'
import {useAsrParameters} from '~/services/asr'
import {ErrorCodes, handleHttpError} from '~/services/error'
import {currentUserChangeDispatcher} from '~/services/user'
import debatch from '~/utils/debatch'
import {delay} from '~/utils/delay'
import {getUseSharedState} from '~/utils/getUseSharedState'
import {http} from '~/utils/http'
import {Semaphore} from '~/utils/semaphore'
import {urlEncode} from '~/utils/sort'
import {toggleMapKey} from '~/utils/toggleMapKey'

import type {IntlMap} from '~/intl/ru'
import type {Sort} from '~/utils/sort'

export type FileSearchRequest = {
    offset?: number
    limit: number
    filter?: {
        name?: string
        contentType?: string
    }
    sort: Sort
}

export type StorageFile = {
    id: string
    name: string
    content_type: string
    created_date: string
    link: string
    md5: string
    metadata: Record<string, unknown>
    properties: Record<string, unknown>
    owner_id: string
    size: number
    tags: string[]
}

export function getFile(id: string) {
    return http.get<StorageFile>(`${BACKEND.api}/storage/files/${id}`)
        .catch(handleHttpError)
}

export function searchFiles({offset = 0, limit, filter = {}, sort}: FileSearchRequest) {
    const request = {
        offset,
        limit: limit * (PAGINATION_SIZE - 1) + 1,
        sort: urlEncode(sort),
        name_like: filter.name || undefined,
        content_type_like: filter.contentType || undefined,
    }

    return http.get<{data: StorageFile[]}>(`${BACKEND.api}/storage/files`, request)
        .then(result => ({
            total: offset + result.data.length,
            files: result.data.slice(0, limit),
        }))
        .catch(handleHttpError)
}

// TODO подумать на тему утилиты по обогащению хука кастомными методами
export const useFileSelectionMap = getUseSharedState(new Map<string, StorageFile>)

export function deleteFiles(ids: string | string[]) {
    ids = [ids].flat()
    return Promise.all(ids.map(id =>
        http.delete(`${BACKEND.api}/storage/files/${id}`)
            .finally(() => useFileSelectionMap.dispatch(toggleMapKey(useFileSelectionMap.getState(), id)))
    ))
        .finally(() => currentUserChangeDispatcher.dispatch({}))
}

export const useUploadingFiles = getUseSharedState<File[]>([])

const concurrentUploads = 4
const uploadSemaphore = new Semaphore(concurrentUploads)

function upload(file: File, additionalData = {}) {
    const formData = new FormData()

    formData.append('parameters', new Blob([JSON.stringify(additionalData)], {type: 'application/json'}))
    formData.append('file', file)

    useUploadingFiles.dispatch([...useUploadingFiles.getState(), file])

    const asrParameters = useAsrParameters.getState()

    return uploadSemaphore.acquire()
        .then(() => http.post(
            `${BACKEND.api}/process`,
            formData,
            {
                ...asrParameters,
                model_ids: asrParameters.model_ids.join(),
            }
        ))
        .finally(() => {
            uploadSemaphore.release()
            currentUserChangeDispatcher.dispatch({})
            useUploadingFiles.dispatch(useUploadingFiles.getState().filter(_file => _file != file))
        })
        .catch(handleHttpError)
}

export function uploadFiles(files: File[]) {
    return Promise.all(files.map(file => upload(file)))
}

export function downloadFiles(files: StorageFile[]) {
    files.forEach((file, index) =>
        delay(300 * index)
            .then(() => window.open(`${BACKEND.audioUrlPrefix}${file.link}`, '_self'))
    )
}

export type AsrTask = {
    id: string
    file_id: string
    created_date: string
    status: keyof IntlMap['_task.status']
    completed_date?: string
    duration?: number
    model_id?: string
    model_ids?: string[]
    enable_automatic_punctuation?: false
    max_alternatives?: number
    word_to_number?: boolean
    interruption_word_threshold?: number
    interruption_duration_threshold?: number
    process_as_mono?: boolean
    diarization?: boolean
    sentiment_analysis?: boolean
}

export function searchTaskForFileBatch(fileIds: string[]) {
    return http.post<{data: AsrTask[]}>(`${BACKEND.api}/asr/tasks/search`, {
        filter: {
            file_ids: fileIds,
            deleted: false,
        },
        // требуется умножение, т.к. для одного файла может быть несколько задач, в том числе отвалившиxся
        limit: fileIds.length * 100,
    })
        .then(({data}) => data)
}

export const searchTaskForFile = debatch(
    0,
    searchTaskForFileBatch,
    (fileId: string, tasks: AsrTask[]) =>
        tasks.find(task => task.file_id == fileId) || Promise.reject({code: ErrorCodes.somethingGoneWrong})
)

export function deleteTask(id: string) {
    return http.delete(`${BACKEND.api}/asr/tasks/${id}`)
}

export function createTask(fileId: string) {
    return http.post(
        `${BACKEND.api}/asr/tasks`,
        {
            file_id: fileId,
            ...useAsrParameters.getState(),
        },
    )
}

export const useTaskChangeDispatcher = getUseSharedState({})

export type AsrWord = {
    confidence: number
    greedy_score: number
    word: string
}

export type AsrResultData = {
    alternatives: AsrWord[]
    begin: number
    end: number
    loudness: number
    speaker_id: string
}

export type Sentiment = {
    confidence: number
    sentiment: 'neutral' | 'positive' | 'negative'
}

export type AsrPhraseDescription = {
    avg_greedy_score: number
    avg_loudness: number
    channel_str: string
    id: number
    max_loudness: number
    min_loudness: number
    speaker_id: string
    words_count: number
    words_per_second: number
    sentiments?: Sentiment[]
}

export type AsrChannelInfo = {
    channel: number
    duration: number
    interruptions_count: number
    loudness: number
    share: number
    silence: number
    speech: number
    words_count: number
    words_per_second: number
}

export type AsrTaskResult = AsrTask & {
    metrics: {
        channels_info: AsrChannelInfo[]
        duration: number
        interruptions_count: number
        overall_interrupt: number
        overall_silence: number
        overall_speech: number
    }
    phrases: AsrPhraseDescription[]
    result: {
        avg_greedy_score: number
        channel: number
        data: AsrResultData[]
        id: number
        loudness: number
    }[]
    // бэкенд возвращает и следующие свойства:
    // silence_intervals: {
    //     begin: number
    //     end: number
    //     from: string
    //     to: string
    // }[]
    // task_end_time: string
    // model_metainfo: string
    // audio: {
    //     file_url: string
    // }
    // channels_energy: {
    //     channel: number
    //     energy: number[]
    //     step_ms: number
    // }[]
}

export function getTaskResult(taskId: string) {
    return http.get<AsrTaskResult>(`${BACKEND.api}/asr/tasks/${taskId}`, {
        result: true,
        output_format: 'segmented_confusion_network',
    })
}
