import {interfaceLanguage} from '~/services/intl'

const formatters = ([
    {
        maxSeconds: 60 * 60,
        options: {
            minute: 'numeric',
            second: 'numeric',
        },
    },
    {
        maxSeconds: Number.POSITIVE_INFINITY,
        options: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        },
    },
] as const)
    .map(timeFormat => ({
        ...timeFormat,
        format: new Intl.DateTimeFormat(interfaceLanguage, {
            ...timeFormat.options,
            timeZone: 'UTC',
        }).format,
    }))

export const formatDuration = (seconds: number) => {
    const time = new Date(seconds * 1000)
    const format = formatters.find(({maxSeconds}) => seconds < maxSeconds)?.format || String

    return format(time)
}
