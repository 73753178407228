import clsx from 'clsx'

type Props = {
    children: React.ReactNode
    ready?: boolean
    className?: string
}

export default function({children, className, ready = true}: Props) {
    return (
        <div className={clsx('dialog__footer', className)}>
            {ready && children}
            {!ready && <progress/>}
        </div>
    )
}
