import {useUploadingFiles} from '~/services/file'
import {trlCount} from '~/services/intl'

export default function() {
    const [files] = useUploadingFiles()

    const title = files
        .map(file => file.name)
        .join('\n')

    if (files.length == 0)
        return null

    return (
        <span className='file-upload-text' title={title}>
            {trlCount('Загружается %0 файлов', files.length)}
        </span>
    )
}
