import {Fragment, useEffect, useMemo, useState} from 'react'

import Dialog from '../dialog/Dialog'
import {trl} from '~/services/intl'
import {formatMonth} from '~/utils/formatDate'
import {formatMinutes} from '~/utils/formatMinutes'

import type {UsageHistoryItem, User} from '~/services/user'

type GroupHistory = {
    year: number
    months: {
        year: number
        month: number
        seconds: number
    }[]
}

type Props = {
    user?: User
    visible: boolean
    onHide: () => void
}

export default function({user, visible, onHide}: Props) {
    const [usageHistory, setUsageHistory] = useState<UsageHistoryItem[]>()

    useEffect(() => {
        user && setUsageHistory(user.usage_history)
    }, [user])

    const years = useMemo(
        () => usageHistory
            ?.toReversed()
            .reduce<GroupHistory[]>(
                (years, monthData) => {
                    if (!years.at(-1) || years.at(-1)?.year != monthData.year)
                        years.push({
                            year: monthData.year,
                            months: [],
                        })
                    years.at(-1)?.months.push(monthData)
                    return years
                },
                []
            ),
        [usageHistory]
    )

    if (!years)
        return null

    return (
        <Dialog
            className='dialog dialog_medium-fix user-history'
            visible={visible}
            onHide={onHide}
            header={trl('История потребления %0', user?.username)}
        >
            <form className='dialog__form'>
                <div className='dialog__content'>
                    <div>
                        {years.map(yearData =>
                            <Fragment key={yearData.year}>
                                <span className='row row_center user-history__year'>
                                    {yearData.year}
                                </span>
                                <div className='row row_center row_offset-2'>
                                    <span className='col col_3 col_offset-4 text text_right user-history__month'>
                                        {trl('Минуты')}
                                    </span>
                                    <span className='col col_3 col_offset-4 text text user-history__month'>
                                        {trl('Месяц')}
                                    </span>
                                </div>
                                {yearData.months.map(monthData =>
                                    <div key={monthData.month} className='row row_center row_offset-2'>
                                        <div className='col col_3 col_offset-4 col_offset-clear text text_right'>
                                            {formatMinutes(monthData.seconds)}
                                        </div>
                                        <div className='col col_3 col_offset-4 col_offset-clear text text_left'>
                                            {formatMonth(monthData.month)}
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        )}
                        {!years.length && <span className='row row_center'>{trl('Нет данных')}</span>}
                    </div>
                </div>
            </form>
        </Dialog>
    )
}
