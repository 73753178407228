
import Icon from '../Icon'
import {trlObject} from '~/services/intl'

export default function() {
    const {header, blocks, subtitle} = trlObject('_landing.boxes')['8']

    const renderText = (text: string, key?: string | number) => <span key={key} className='landing__text'>
        {text}
    </span>

    return (
        <div className='landing-box-8 row row_col-center'>
            <h1 className='landing__header'>
                {header}
            </h1>
            <small className='landing-box-8__subtitle landing__subtitle'>{subtitle}</small>
            <div className='landing-box-8__cards'>
                {blocks.map(({title, texts, list}, index) =>
                    <div key={index} className={`landing-box-8__card landing-box-8__card_${index} row row_gap-6 row_col-center`}>
                        <Icon className='landing-box-8__card-icon' name={`8-${index}`}/>
                        <h5 className='landing-box-8__card-title'>
                            {title}
                        </h5>
                        <div className='landing__text landing-box-8__card-texts text text_wrap'>
                            {texts?.map((text, key) => renderText(text, key))}
                            {list &&
                                <ul className='list'>
                                    {list.map((text, key) => <li key={key}>{renderText(text)}</li>)}
                                </ul>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
