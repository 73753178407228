import {Dialog} from 'primereact/dialog'

import type {DialogProps} from 'primereact/dialog'

type Props = DialogProps & {
    visible: boolean
}

export default function(props: Props) {
    return (
        <Dialog
            className='dialog'
            keepInViewport
            resizable={false}
            draggable={false}
            blockScroll
            {...props}
        />
    )
}
