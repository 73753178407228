import {trl} from '~/services/intl'
import {formatChatTime} from '~/utils/formatDate'

import type {AsrPhraseDescription, AsrResultData, AsrTaskResult, AsrWord} from '~/services/file'

const punctuationChars = '.,;:!?'

export function extractChat(taskResult: AsrTaskResult) {
    const {channelCount, phrases} = getPhrases(taskResult)

    return [
        ...channelCount == 1
            ? getMonolog(phrases)
            : getChat(phrases),
    ]
        .join('')
}

export type PhraseWord = AsrResultData & AsrWord

export type Phrase = AsrPhraseDescription & {
    channel: number
    begin: number
    words: PhraseWord[]
}

function* getMonolog(phrases: Phrase[]) {
    for (const {words} of phrases)
        for (const {word} of words) {
            yield ' '
            yield word
        }
}

function* getChat(phrases: Phrase[]) {
    let previousChannel = Number.POSITIVE_INFINITY

    for (const {channel, begin, words} of phrases) {
        if (previousChannel != channel) {
            yield '\n'
            yield trl('_chat.message.title', channel + 1, formatChatTime(begin))
        }

        const phrase = words.map(({word}) => word)
            .join(' ')

        yield ' '
        yield phrase

        previousChannel = channel
    }
}

export function getPhrases(taskResult: AsrTaskResult) {
    const diarizationActive = taskResult.diarization
    const phrasesMetadata = taskResult.phrases
    const rawPhrases = taskResult.result
    const channelMap = Object.fromEntries(
        diarizationActive
            ? Object.keys(
                phrasesMetadata
                    .reduce<Record<string, true>>(
                        (map, phrase) => {
                            map[phrase.speaker_id] = true
                            return map
                        },
                        {}
                    )
            )
                .map((speakerId, index) => [speakerId, index])
            : taskResult.metrics.channels_info
                .map(({channel}, index) => [channel, index])
    )

    const phrases: Phrase[] = rawPhrases
        .map(({channel, data, id}) => ({
            channel: channelMap[diarizationActive ? data[0].speaker_id : channel],
            begin: data[0].begin * 1000,
            words: getPhraseWords(data),
            ...phrasesMetadata[id],
        }))
        .sort(({begin: begin1}, {begin: begin2}) => begin1 - begin2)

    return {
        channelCount: Object.keys(channelMap).length,
        phrases,
    }
}

function getPhraseWords(rawPhraseWords: AsrResultData[]) {
    return rawPhraseWords
        .map(rawWord => ({
            ...rawWord,
            ...rawWord.alternatives[0],
            begin: rawWord.begin * 1000,
            end: rawWord.end * 1000,
        }))
        .reduce<PhraseWord[]>(
            (newWords, wordData) => {
                const lastWord = newWords.at(-1)

                if (punctuationChars.includes(wordData.word) && lastWord)
                    lastWord.word += wordData.word
                else
                    newWords.push(wordData)

                return newWords
            },
            []
        )
}
