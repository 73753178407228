import {trl} from '~/services/intl'
import {useCurrentUser} from '~/services/user'

export default function() {
    const [currentUser] = useCurrentUser()

    if (!currentUser)
        return null

    return (
        <div className='usage-metric usage-metric_minute'>
            <span className='usage-metric__title'>
                {trl('Минуты')}
            </span>
            <meter
                className='usage-metric__indicator'
                value={currentUser.usage.seconds}
                low={0.95 * currentUser.limits.seconds}
                high={0.95 * currentUser.limits.seconds}
                max={currentUser.limits.seconds}
            />
            <div
                className='usage-metric__info'
                dangerouslySetInnerHTML={{__html: trl(
                    'Использовано %0 из %1.html',
                    currentUser.usage.seconds / 60 | 0,
                    currentUser.limits.seconds / 60 | 0,
                )}}
            />
        </div>
    )
}
