import clsx from 'clsx'
import {Fragment, useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'

import {loadApiDocumentationJSON, preparePath} from './apiList'
import OverallProgress from '../OverallProgress'
import {interfaceLanguage, trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'

type TopicItem = {
    name: string
    path: string
    root?: string
    method?: string
    isDynamicPage?: boolean
    items?: TopicItem[]
}

type Topics = {
    name: string
    path: string
    items: TopicItem[]
}[]

export default function() {
    const [collapsedSectionsSet, setCollapsedSectionsSet] = useState<Set<string>>(new Set())
    const [documentationTopics, setDocumentationTopics] = useState<Topics>()

    useEffect(() => {
        loadApiDocumentationJSON(interfaceLanguage)
            .then(apiMethods => {
                setDocumentationTopics([
                    // TODO вынести всю навигацию в общий JSON
                    {
                        name: trl('Распознавание речи'),
                        path: 'recognition',
                        items: [
                            {
                                name: trl('Технология'),
                                path: 'technology',
                            },
                            {
                                name: trl('Технические характеристики'),
                                path: 'specifications',
                            },
                        ],
                    },
                    {
                        name: trl('Синтез'),
                        path: 'synthesis',
                        items: [
                            {
                                name: trl('Технология'),
                                path: 'technology',
                            },
                            {
                                name: trl('Список голосов'),
                                path: 'voices',
                            },
                            {
                                name: trl('Нормализация текста'),
                                path: 'normalization',
                            },
                            {
                                name: trl('Разметка текста'),
                                path: 'markup',
                            },
                        ],
                    },
                    {
                        name: 'API',
                        path: 'api',
                        items: [
                            {
                                name: trl('Авторизация'),
                                path: 'oauth-api',
                                items: [
                                    ...apiMethods.oauth.map(item => ({
                                        name: item.description,
                                        path: preparePath(item.method.type + item.method.url),
                                        root: 'oauth',
                                        method: item.method.type,
                                        isDynamicPage: true,
                                    })),
                                ],
                            },
                            {
                                name: trl('Профиль'),
                                path: 'profile-api',
                                items: [
                                    ...apiMethods.profile.map(item => ({
                                        name: item.description,
                                        path: preparePath(item.method.type + item.method.url),
                                        root: 'profile',
                                        method: item.method.type,
                                        isDynamicPage: true,
                                    })),
                                ],
                            },
                            {
                                name: trl('Распознавание'),
                                path: 'recognition-api',
                                items: [
                                    {
                                        name: 'API распознавания аудиофайлов (gRPC)',
                                        path: 'recognition-recognize-gRPC',
                                        method: 'grpc',
                                        root: '',
                                        isDynamicPage: false,
                                    },
                                    ...apiMethods.asr.map(item => ({
                                        name: item.description,
                                        path: preparePath(item.method.type + item.method.url),
                                        method: item.method.type,
                                        root: 'asr',
                                        isDynamicPage: true,
                                    })),
                                ],
                            },
                            {
                                name: trl('Синтез'),
                                path: 'synthesis-api',
                                items: [
                                    {
                                        name: 'API синтеза речи (gRPC)',
                                        path: 'synthesis-gRPC',
                                        method: 'grpc',
                                        root: '',
                                        isDynamicPage: false,
                                    },
                                    ...apiMethods.tts.map(item => ({
                                        name: item.description,
                                        path: preparePath(item.method.type + item.method.url),
                                        method: item.method.type,
                                        root: 'tts',
                                        isDynamicPage: true,
                                    })),
                                ],
                            },
                            {
                                name: trl('Дикторы'),
                                path: 'speakers-api',
                                items: [
                                    ...apiMethods.speakers.map(item => ({
                                        name: item.description,
                                        path: preparePath(item.method.type + item.method.url),
                                        root: 'speakers',
                                        method: item.method.type,
                                        isDynamicPage: true,
                                    })),
                                ],
                            },
                            {
                                name: trl('Хранилище'),
                                path: 'storage-api',
                                items: [
                                    ...apiMethods.storage.map(item => ({
                                        name: item.description,
                                        path: preparePath(item.method.type + item.method.url),
                                        root: 'storage',
                                        method: item.method.type,
                                        isDynamicPage: true,
                                    })),
                                ],
                            },

                        ],
                    },
                ])
            })
            .catch(showErrorPopup)
    }, [])

    const toggle = (section: string) => {
        const newCollapsedSectionsSet = new Set(collapsedSectionsSet)

        if (!newCollapsedSectionsSet.delete(section))
            newCollapsedSectionsSet.add(section)

        setCollapsedSectionsSet(newCollapsedSectionsSet)
    }

    if (!documentationTopics)
        return (
            <div className='documentation-menu documentation-menu__wrapper'>
                <OverallProgress/>
            </div>
        )

    return (
        <div className='documentation-menu documentation-menu__wrapper'>
            {documentationTopics.map(item => <Fragment key={item.path}>
                <button
                    className='documentation-menu__item'
                    onClick={() => toggle(item.path)}
                >
                    <i className={clsx('pi', collapsedSectionsSet.has(item.path) ? 'pi-angle-right' : 'pi-angle-down')}/>
                    <span className='text-overflow'>
                        {item.name}
                    </span>
                </button>
                {!collapsedSectionsSet.has(item.path) && item.items.map(subItem =>
                    subItem.items
                        ? <Fragment key={subItem.path}>
                            <button
                                className='documentation-menu__item'
                                onClick={() => toggle(subItem.path)}
                                style={{paddingLeft: '40px'}}
                            >
                                <i className={clsx('pi', collapsedSectionsSet.has(subItem.path) ? 'pi-angle-right' : 'pi-angle-down')}/>
                                <span className='text-overflow'>
                                    {subItem.name}
                                </span>
                            </button>
                            {!collapsedSectionsSet.has(subItem.path) && subItem.items.map(method =>
                                <Fragment key={`${item.path}-${method.path}`}>
                                    <NavLink
                                        key={`${item.path}-${method.path}`}
                                        to={method.isDynamicPage ? `${method.root}/${method.path}` : `${item.path}-${method.path}`}
                                        style={{paddingLeft: '64px'}}
                                        className={({isActive}) => clsx(
                                            'documentation-menu__item documentation-menu__item_inner',
                                            isActive && 'documentation-menu__item_active'
                                        )}
                                    >
                                        <div className='row row_gap-4 row_nowrap'>
                                            <div>
                                                <div
                                                    className={clsx(
                                                        'api__method-type text text_uppercase',
                                                        `api__method-type_${method.method}`
                                                    )}
                                                >
                                                    {method.method}
                                                </div>
                                            </div>
                                            <span style={{lineHeight: 'normal'}}>
                                                {method.name}
                                            </span>
                                        </div>
                                    </NavLink>
                                </Fragment>
                            )}
                        </Fragment>

                        : <NavLink
                            key={`${item.path}-${subItem.path}`}
                            to={`${item.path}-${subItem.path}`}
                            state={{
                                isDynamicPage: false,
                            }}
                            className={({isActive}) => clsx(
                                'documentation-menu__item documentation-menu__item_inner',
                                isActive && 'documentation-menu__item_active'
                            )}
                        >
                            {subItem.name}
                        </NavLink>
                )}
            </Fragment>)}
        </div>
    )
}
