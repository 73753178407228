import clsx from 'clsx'

import type {HTMLProps} from 'react'

type Props = HTMLProps<HTMLInputElement> & {
    onFilesSelect: (files: File[]) => void
}

export default function FilePicker({onFilesSelect, children, className, ...props}: Props) {
    return (
        <label className={clsx('file-picker', className)}>
            <input
                type='file'
                className='file-picker__input'
                onChange={({target}) => {
                    onFilesSelect([...target.files || []])
                    target.value = ''
                }}
                {...props}
            />
            {children}
        </label>
    )
}
