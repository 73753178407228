import FilePicker from '~/components/file/FilePicker'
import {uploadFiles} from '~/services/file'
import {trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'

export default function() {
    const handleFileSelect = (files: File[]) =>
        uploadFiles(files)
            .catch(showErrorPopup)

    return (
        <FilePicker className='p-button row row_center' multiple onFilesSelect={handleFileSelect}>
            {trl('Загрузить')}
        </FilePicker>
    )
}
