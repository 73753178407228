import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import TaskStatusIcon from '../TaskStatusIcon'
import TooltipTitle from '../TooltipTitle'
import ContextMenu from '~/components/ContextMenu'
import FileSelectCheckbox from '~/components/file/FileSelectCheckbox'
import {confirm} from '~/services/confirmationDialog'
import {
    createTask,
    deleteFiles,
    deleteTask,
    downloadFiles,
    searchTaskForFile,
    useTaskChangeDispatcher,
} from '~/services/file'
import {trl, trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {getSharedDelay} from '~/utils/debatch'
import {formatDate} from '~/utils/formatDate'
import {formatDuration} from '~/utils/formatDuration'
import {formatSize} from '~/utils/formatSize'
import {downloadTranscriptions} from '~/utils/transcript'
import {mergeSearchString} from '~/utils/url'

import type {StorageFile, AsrTask} from '~/services/file'

const sharedRefreshDelay = getSharedDelay(5000)

type Props = {
    file: StorageFile
}

export default function({file}: Props) {
    const [task, setTask] = useState<AsrTask>()
    const [taskChange] = useTaskChangeDispatcher()

    const refModelId = useRef<HTMLElement>(null)

    useEffect(
        () => {
            let alive = true

            const refresh = () =>
                searchTaskForFile(file.id)
                    .then(task => {
                        setTask(task)

                        if (task.status == 'process')
                            sharedRefreshDelay()
                                .then(alive ? refresh : undefined)
                    })

            refresh()

            return () => {
                alive = false
            }
        },
        [file.id, taskChange]
    )

    const taskStatus = task?.status || 'error'

    const menuItems = [
        {
            label: trl('Переобработать'),
            command: () => searchTaskForFile(file.id)
                .catch(() => undefined)
                .then(task => Promise.all([
                    task && deleteTask(task.id),
                    createTask(file.id),
                ]))
                .catch(showErrorPopup)
                .finally(() => useTaskChangeDispatcher.dispatch({})),
        },
        {
            label: trl('Скачать'),
            command: () => downloadFiles([file]),
        },
        {
            label: trl('Скачать расшифровку'),
            command: () => downloadTranscriptions([file]),
        },
        {
            label: trl('Удалить'),
            command: () => confirm(
                trl('Удалить?'),
                file.name,
                trl('Удалить'),
            )
                .then(() => deleteFiles(file.id))
                .catch(showErrorPopup),
        },
    ]

    return (
        <ContextMenu
            tag='div'
            items={menuItems}
            className='file-list-row row row_nowrap'
        >
            <div className=' col_tall file-list-row__col'>
                <FileSelectCheckbox file={file}/>
            </div>
            <div className='file-list-row__col'>
                <Link
                    className='file-list-row__filename text-overflow'
                    to={`./${file.id}${mergeSearchString({})}`}
                    title={file.name}
                >
                    {file.name}
                </Link>
            </div>
            <div
                className='file-list-row__col'
                title={trlObject('_task.status')[taskStatus]}
            >
                <TaskStatusIcon status={taskStatus}/>
            </div>
            <div className='file-list-row__col'>
                {formatDate(new Date(file.created_date))}
            </div>
            <div className='file-list-row__col'>
                {task && formatDate(new Date(task.created_date))}
            </div>
            <div className='file-list-row__col'>
                {task?.duration && formatDuration(task.duration)}
            </div>
            <div className='file-list-row__col'>
                {formatSize(file.size)}
            </div>
            <div className='file-list-row__col text-overflow'>
                <TooltipTitle
                    target={refModelId.current as HTMLElement}
                    content={task?.model_id || task?.model_ids?.join('\n')}
                />
                <span ref={refModelId}>
                    {task?.model_id || task?.model_ids?.[0]}
                </span>
            </div>
        </ContextMenu>
    )
}
