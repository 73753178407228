import clsx from 'clsx'

import Icon from '../Icon'

import type {StorageFile} from '~/services/file'
import type {Sort} from '~/utils/sort'

type UserField = 'username' | 'role' | 'status' | 'usage.seconds' | 'usage.storage_capacity' | 'usage.task_count' | 'created_date'

type Props = {
    sort: Sort
    field: keyof StorageFile | UserField
    onSortChange: (sort: Sort) => void
    children: React.ReactNode
    className?: string
}

export default function({sort, field, onSortChange, children, className}: Props) {
    const handleClick = () => {
        onSortChange({
            field,
            ascending: sort.field != field || !sort.ascending,
        })
    }

    return (
        <button
            className={className}
            type='button'
            onClick={handleClick}
        >
            {children}
            <div className='sort-icon-container'>
                <Icon
                    name='sortArrowUp'
                    className={clsx('sort-icon-container__icon', sort.field == field && sort.ascending && 'sort-icon-container__icon_active')}
                />
                <Icon
                    name='sortArrowDown'
                    className={clsx('sort-icon-container__icon', sort.field == field && !sort.ascending && 'sort-icon-container__icon_active')}
                />
            </div>
        </button>
    )
}
