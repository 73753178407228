import {Carousel} from 'primereact/carousel'
import {Link} from 'react-router-dom'

import Icon from '../Icon'
import {trlObject} from '~/services/intl'

const responsiveOptions = [
    {
        breakpoint: '1400px',
        numVisible: 5,
        numScroll: 2,
    },
    {
        breakpoint: '1250px',
        numVisible: 4,
        numScroll: 2,
    },
    {
        breakpoint: '1100px',
        numVisible: 3,
        numScroll: 2,
    },
    {
        breakpoint: '800px',
        numVisible: 2,
        numScroll: 2,
    },
    {
        breakpoint: '400px',
        numVisible: 1,
        numScroll: 2,
    },
]

export default function() {
    const {icons, header} = trlObject('_landing.boxes')['11']

    return <div className='landing-box-11 row row_col-center row_tall row_gap-14'>
        <h1 className='landing__header'>
            {header}
        </h1>
        <Carousel
            responsiveOptions={responsiveOptions}
            className='landing-box-11__carousel'
            numScroll={1}
            numVisible={6}
            value={icons}
            itemTemplate={([icon, url]) =>
                <Link
                    key={icon as string}
                    to={url as string}
                    replace
                >
                    <Icon className='landing-box-11__carousel_icon' name={icon as string}/>
                </Link>
            }
        />
    </div>
}
