import {Button} from 'primereact/button'
import {Checkbox} from 'primereact/checkbox'
import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {useState} from 'react'

import {trl, trlObject} from '~/services/intl'
import {showErrorPopup, showPopup} from '~/services/popup'
import {defaultFeedbackData, postFeedback} from '~/services/user'
import typedObjectEntries from '~/utils/typedObjectEntries'

import type {InputTextProps} from 'primereact/inputtext'

const booleanInputs = ['personalData', 'advertisements']
const customInputs = ['suggest', ...booleanInputs]

const deliveryTypeOptions = [{value: 'SaaS', label: 'SaaS'}, {value: 'On-premise', label: 'On-premise'}]

const inputPropsMap: Record<string, InputTextProps> = {
    email: {type: 'email'},
    phone: {type: 'tel'},
}

export default function() {
    const {subtitle, header, form: defaultForm} = trlObject('_landing.boxes')['10']
    const [form, setForm] = useState(defaultFeedbackData)
    const {suggest, personalData, advertisements} = form

    return (
        <div id='suggestForm' className='landing-box-10 row row_col-center custom-controls'>
            <h1 className='landing__header landing-box-10__title'>
                {header}
            </h1>
            <small className='landing__subtitle landing-box-10__subtitle'>{subtitle}</small>
            <div className='landing-box-10__inputs row row_col-center row_gap-8'>
                <div className='landing-box-10__inputs_grid'>
                    {typedObjectEntries(form)
                        .filter(([key]) => !customInputs.includes(key))
                        .map(([key, value]) =>
                            <label key={key} className='control'>
                                {key != 'deliveryType' &&
                                    <InputText
                                        placeholder={defaultForm[key] as string}
                                        value={value as string}
                                        onChange={event => setForm({...form, [key]: event.target.value})}
                                        {...inputPropsMap[key]}
                                    />
                                }
                                {key == 'deliveryType' &&
                                    <Dropdown
                                        placeholder={defaultForm[key]}
                                        value={value}
                                        options={deliveryTypeOptions}
                                        onChange={({value}) => setForm({...form, [key]: value as string})}
                                    />
                                }
                            </label>
                        )
                    }
                </div>
                <InputTextarea
                    className='landing-box-10__inputs_textarea'
                    placeholder={defaultForm.suggest}
                    value={suggest}
                    onChange={event => setForm({...form, suggest: event.target.value})}
                />
                <div className='landing-box-10__inputs_checkboxes row row_col'>
                    <div className='register__inputs_checkbox row row_nowrap row_gap-2'>
                        <Checkbox
                            checked={advertisements}
                            type='checkbox'
                            onChange={({checked}) => setForm({...form, advertisements: !!checked})}
                        />
                        <span>
                            {trlObject('_landing.boxes')[10].form.advertisements}
                        </span>
                    </div>
                    <div className='register__inputs_checkbox row row_nowrap row_gap-2'>
                        <Checkbox
                            checked={personalData}
                            type='checkbox'
                            onChange={({checked}) => setForm({...form, personalData: !!checked})}
                        />
                        <span>
                            {trlObject('_landing.boxes')[10].form.personalData[0]}&nbsp;
                            <a target='_blank' href='/static/policy.pdf' className='link'>
                                {trlObject('_landing.boxes')[10].form.personalData[1]}
                            </a>.
                        </span>
                    </div>
                </div>
            </div>
            <Button
                className='landing__button landing-blue-button'
                onClick={() => postFeedback(form)
                    .then(showPopup)
                    .catch(showErrorPopup)
                    .finally(() => setForm(defaultFeedbackData))
                }
            >
                {trl('Подать заявку')}
            </Button>
        </div>
    )
}
