import {useState} from 'react'

import AsrParametersDialog from '~/components/AsrParametersDialog'
import {trl} from '~/services/intl'

export default function() {
    const [visible, setVisible] = useState(false)

    return <div className='upload-settings'>
        <button className='select-model-button' onClick={() => setVisible(true)}>
            {trl('Настроить распознавание речи')}
        </button>
        <AsrParametersDialog
            visible={visible}
            onDone={() => setVisible(false)}
        />
    </div>
}
