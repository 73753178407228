import clsx from 'clsx'
import {Button} from 'primereact/button'

import DialogFooter from './dialog/DialogFooter'
import Dialog from '~/components/dialog/Dialog'

import type {ConfirmProps} from '~/services/confirmationDialog'

export default function({header, message, actionLabel, danger, onDone}: ConfirmProps) {
    return (
        <Dialog
            onHide={() => onDone(false)}
            className='dialog'
            visible
            header={
                <span className={clsx('text', danger && 'text_assertive')}>
                    {header}
                </span>
            }
        >
            <form className='dialog__form'>
                <div className='dialog__content'>
                    <span className='text text_wrap'>
                        {message}
                    </span>
                </div>
                <DialogFooter>
                    <Button
                        type='button'
                        className={clsx(danger && 'p-button-danger')}
                        onClick={() => onDone(true)}
                    >
                        {actionLabel}
                    </Button>
                </DialogFooter>
            </form>
        </Dialog>
    )
}
