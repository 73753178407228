import {currentUserChangeDispatcher} from './user'

export type RawBackendError = {
    resource: 'vox' | 'auth' | 'frontend'
    code?: number
    message?: string
}

export type BackendError = {
    code: string
    message?: string
}

export const enum ErrorCodes {
    accessDenied = 'vox:undefined',
    somethingGoneWrong = 'frontend:1',
    audioError = 'frontend:3',
    recognitionError = 'frontend:4',
    userCancel = 'frontend:5',
    uncomplete = 'frontend:6',
}

export function handleHttpError(httpResponse?: {error?: RawBackendError}): never {
    // для отладки
    // console.error(httpResponse)

    const code = httpResponse?.error
        ? `${httpResponse.error.resource}:${httpResponse.error.code}`
        : ErrorCodes.somethingGoneWrong

    if (code == ErrorCodes.accessDenied)
        currentUserChangeDispatcher.dispatch({})

    throw {
        ...httpResponse?.error,
        code,
    } satisfies BackendError
}
