import {useEffect, useState} from 'react'

export function useObjectUrl(initialBlob?: Blob) {
    const [blob, setBlob] = useState<Blob | undefined>(initialBlob)
    const [objectUrl, setObjectUrl] = useState<string>()

    useEffect(
        () => {
            if (!blob)
                return

            const objectUrl = URL.createObjectURL(blob)
            setObjectUrl(objectUrl)

            return () => {
                URL.revokeObjectURL(objectUrl)
                setObjectUrl('')
            }
        },
        [blob],
    )

    return [objectUrl, setBlob, blob] as const
}
