import {useEffect, useState} from 'react'

import Dialog from './dialog/Dialog'
import Icon from './Icon'
import {trl} from '~/services/intl'
import {showErrorPopup, showPopup} from '~/services/popup'
import {type ClientData, createClientData, getClientData} from '~/services/user'

type Props = {
    textMode?: boolean
}

export default function({textMode}: Props) {
    const [infoDialog, setInfoDialog] = useState(false)
    const [clientData, setClientData] = useState<ClientData>()

    useEffect(
        () => {
            if (!infoDialog)
                return

            getClientData()
                .catch(createClientData)
                .then(setClientData)
                .catch(showErrorPopup)
        },
        [infoDialog]
    )

    return (
        <>
            <button onClick={() => setInfoDialog(!infoDialog)}>
                {textMode && <div className='user-info__info_exit'>{trl('Для разработчиков')}</div>}
                {!textMode && <Icon name='code' className='header__icon'/>}
            </button>
            {clientData &&
                <Dialog
                    className='user-info__dialog'
                    visible={infoDialog}
                    onHide={() => setInfoDialog(false)}
                >
                    <div className='row row_col-center row_gap-14'>
                        <span className='landing__header user-info__title'>
                            {trl('Информация для разработчиков')}
                        </span>
                        <span className='user-info__input'>
                            <span>{trl('Идентификатор клиента')}</span>
                            <button onClick={() => navigator
                                .clipboard
                                .writeText(clientData.client_id)
                                .then(() => showPopup(trl('Скопировано')))
                            }>
                                <Icon name='copy' className='user-info__icon'/>
                            </button>
                        </span>
                        <span className='user-info__input'>
                            <span>{trl('Секретный ключ')}</span>
                            <button onClick={() => navigator
                                .clipboard
                                .writeText(clientData.client_secret)
                                .then(() => showPopup(trl('Скопировано')))
                            }>
                                <Icon name='copy' className='user-info__icon'/>
                            </button>
                        </span>
                    </div>
                </Dialog>
            }
        </>
    )
}
