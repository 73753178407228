import {trl} from '~/services/intl'
import {type User} from '~/services/user'
import {formatDate} from '~/utils/formatDate'
import {formatMinutes} from '~/utils/formatMinutes'
import {formatSize} from '~/utils/formatSize'

type Props = {
    user: User
    setUserSettingsDialog: (user: User) => void
    setUserHistoryDialog: (user: User) => void
}

export default function({user, setUserSettingsDialog, setUserHistoryDialog}: Props) {
    return (
        <div className='user-list-row'>
            <div className='user-list-row__col user-list-row__col_login text text_black'>
                <button
                    className='text-overflow admin-login-button'
                    type='button'
                    title={user.username}
                    onClick={() => setUserSettingsDialog(user)}
                >
                    {user.username}
                </button>
            </div>
            <div className='user-list-row__col user-list-row__col_center text-overflow'>
                {user.role}
            </div>
            <div
                className={`user-list-row__col user-list-row__col_center user-status ${user.status == 'deleted' ? 'user-status_deleted' : ''}`}
                title={trl(user.status == 'active' ? 'Активен' : 'Удален')}
            />
            <div className='user-list-row__col user-list-row__col_center text text_black text-overflow'>
                <button
                    type='button'
                    onClick={() => setUserHistoryDialog(user)}
                >
                    {formatMinutes(user.usage.seconds)} / {formatMinutes(user.limits.seconds)}
                </button>
            </div>
            <div className='user-list-row__col user-list-row__col_center text-overflow'>
                {formatSize(user.usage.storage_capacity)} / {formatSize(user.limits.storage_capacity)}
            </div>
            <div className='user-list-row__col user-list-row__col_center text-overflow'>
                {user.usage.task_count} / {user.limits.task_count}
            </div>
            <div className='user-list-row__col user-list-row__col_center text-overflow'>
                {formatDate(new Date(user.created_date), {dateStyle: 'medium'})}
            </div>
        </div>
    )
}
