import {useNavigate} from 'react-router-dom'

import FileListColumnHeaderSortable from '~/components/file/FileListColumnHeaderSortable'
import {trl} from '~/services/intl'
import {urlEncode} from '~/utils/sort'
import {mergeSearchString} from '~/utils/url'

import type {Sort} from '~/utils/sort'

type Props = {
    sort: Sort
}

export default function({sort}: Props) {
    const navigate = useNavigate()

    const navigateSort = (sort: Sort) => {
        navigate({search: mergeSearchString({sort: urlEncode(sort)})})
    }

    return (
        <ul className='row files__titles'>
            <li className='files__title'/>
            <li className='col col_wide files__title'>
                <FileListColumnHeaderSortable
                    className='files__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='name'
                    onSortChange={navigateSort}
                >
                    {trl('Имя файла')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='col files__title'/>
            <li className='col files__title'>
                <FileListColumnHeaderSortable
                    className='files__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='created_date'
                    onSortChange={navigateSort}
                >
                    {trl('Дата загрузки')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='col files__title'>
                {trl('Дата обработки')}
            </li>
            <li className='col files__title'>
                {trl('Длительность')}
            </li>
            <li className='col files__title'>
                <FileListColumnHeaderSortable
                    className='files__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='size'
                    onSortChange={navigateSort}
                >
                    {trl('Размер')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='col files__title'>
                {trl('Модель')}
            </li>
        </ul>
    )
}
