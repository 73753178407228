import type {IntlMap} from '~/intl/ru'
import type {KeysOfType} from '~/utils/typeHelpers'

const languageLoaders = {
    ru: () => import('~/intl/ru'),
    en: () => import('~/intl/en'),
}

let locale: IntlMap | undefined

// только для использования в тестах
export function loadLocale(_locale: IntlMap) {
    locale = _locale
}

export const interfaceLanguage = window.document.location.hostname.endsWith('.com') ? 'en' : 'ru'

/**
 * Пример:
 *     trl('О платформе')
 * Пример с подстановкой:
 *     trl('Файл %0 будет удален', file.name)
 * Пример с динамическим формированием ключа:
 *     trl(`file.action.${action}`)
 * @param {string} key ключ перевода
 * @param {unknown[]} params подстановочные параметры
 * @returns {string} переведенная строка с подставленными параметрами
 */
export function trl(key: KeysOfType<IntlMap, string>, ...params: unknown[]) {
    const translation = locale?.[key] as string || key

    return translation.replace(/%(\d)/g, (_, number) => `${params[+number]}`)
}

export type TrlCountObject = {one: string}

const pluralSelector = new Intl.PluralRules(interfaceLanguage)

/**
 * Пример:
 *     trlCount('Выбрано %0 файлов', selectedFiles.length)
 * @param {string} key ключ перевода
 * @param {string} params подстановочные параметры
 * @returns {string} переведенная строка c учетом количества и с подставленными параметрами
 */
export function trlCount(key: KeysOfType<IntlMap, TrlCountObject>, ...params: [number, ...unknown[]]) {
    const [actualCount] = params
    const translations = locale?.[key] as Record<string, string | undefined>
    const translation = translations[pluralSelector.select(actualCount)] || key

    return translation.replace(/%(\d)/g, (_, number) => `${params[+number]}`)
}

/**
 * Пример:
 *     trlObject<LocalizationParameters>('localizationParameters')
 * @param {string} key ключ перевода
 * @returns {Object} осоответствующий объект типа LocalizationParameters из файла переводов
 */
export function trlObject<T extends keyof IntlMap>(key: T): IntlMap[T] {
    return locale?.[key] as IntlMap[T]
}

export function loadIntl() {
    const loader = interfaceLanguage in languageLoaders
        ? languageLoaders[interfaceLanguage]
        : languageLoaders.en

    return loader()
        .then(localeModule => localeModule.default)
        .then(profileLocale => locale = profileLocale as typeof locale)
}
