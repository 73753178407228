import {Dropdown} from 'primereact/dropdown'
import {useEffect, useState} from 'react'

import {getStreamModels} from '~/services/asr'
import {trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'

import type {DropdownProps} from 'primereact/dropdown'
import type {Model} from '~/services/asr'

type Props = Omit<DropdownProps, 'onChange' | 'value'> & {
    modelId: string
    onChange: (modelId: string) => void
}

export default function({modelId, onChange, ...props}: Props) {
    const [models, setModels] = useState<Model[]>([])
    const [options, setOptions] = useState<DropdownProps['options']>([])

    useEffect(
        () => {
            const languageLocalization: Record<string, string> = trlObject('_languages')

            getStreamModels()
                .then(models => {
                    const options = models.map(model => ({
                        label: languageLocalization[model.language] || '',
                        value: model.model_id,
                    }))

                    setModels(models)
                    setOptions(options)
                })
                .catch(showErrorPopup)
        },
        []
    )

    if (models.length == 0)
        return null

    return (
        <Dropdown
            {...props}
            options={options}
            value={modelId}
            onChange={({value}: {value: string}) => onChange(value)}
        />
    )
}
