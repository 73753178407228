import {getUseSharedReducer} from './getUseSharedReducer'

/**
 * Создает хук аналогичный useState с разделяемым состоянием
 *
 * @param initial начальное состояние
 */
export function getUseSharedState<State>(initial: State) {
    function reducer(state: State, newState: State) {
        return newState
    }

    return getUseSharedReducer(reducer, initial)
}
