import clsx from 'clsx'

import Icon from '../Icon'
import {trlObject} from '~/services/intl'

export default function() {
    const {header, subtitle, blocks1, blocks2} = trlObject('_landing.boxes')['2']
    return (
        <div className='landing-box-2 row row_col-center'>
            <h1 className='landing__header'>
                {header}
            </h1>
            <small className='landing__subtitle landing-box-2__subtitle'>
                {subtitle}
            </small>
            <div className='landing-box-2__cards row'>
                <div className='row row_top row_col row_gap-8'>
                    {blocks1.map((block, index) =>
                        <Card
                            {...block}
                            key={index}
                            svgName={`2-1-${index}`}
                            reverse={!(index % 2)}
                        />
                    )}
                </div>
                <div className='row row_top row_col row_gap-8'>
                    {blocks2.map((block, index) =>
                        <Card
                            {...block}
                            key={index}
                            svgName={`2-2-${index}`}
                            reverse={!(index % 2)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

type CardProps = {
    reverse: boolean
    title: string
    svgName: string
    texts: string | string[]
}

const Card = ({title, texts, svgName, reverse = false}: CardProps) =>
    <div className={clsx(
        'landing-box-2__card row row_gap-5 row_nowrap',
        !reverse && 'landing-box-2__card_reversed row_reverse'
    )}>
        <div className='landing-box-2__card-text'>
            <div className='row row_col row_top row_gap-4'>
                <h5 className='landing-box-2__card-title'>{title}</h5>
                <p className='landing__text'>{texts}</p>
            </div>
        </div>
        <Icon className='landing-box-2__icon' name={svgName}/>
    </div>

