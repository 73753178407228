import {Button} from 'primereact/button'
import {InputNumber} from 'primereact/inputnumber'
import {useEffect, useState} from 'react'

import Dialog from '../dialog/Dialog'
import DialogFooter from '../dialog/DialogFooter'
import {trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {updateUserLimit} from '~/services/user'
import {formatMinutes} from '~/utils/formatMinutes'

import type {Limits, User} from '~/services/user'

type Props = {
    user?: User
    visible: boolean
    onHide: () => void
}

const giga = 1024 ** 3
const secondsPerMinute = 60

export default function({user, visible, onHide}: Props) {
    const [limits, setLimits] = useState<Limits>()

    useEffect(() => {
        user && setLimits(user.limits)
    }, [user])

    const submit = () => {
        user && limits && updateUserLimit(user.id, limits)
            .then(onHide)
            .catch(showErrorPopup)
    }

    if (!limits)
        return null

    return (
        <Dialog
            className='dialog dialog_medium-fix'
            visible={visible}
            onHide={onHide}
            header={user?.username}
        >
            <form className='dialog__form'>
                <div className='dialog__content'>
                    <div className='grid grid_rg-6'>
                        <label className='grid grid_rg-2'>
                            <span>
                                {trl('Минуты')}
                            </span>
                            <InputNumber
                                value={+formatMinutes(limits.seconds)}
                                onChange={({value}) => value && setLimits({...limits, seconds: value * secondsPerMinute})}
                            />
                        </label>
                        <label className='grid grid_rg-2'>
                            <span>
                                {trl('Моделей дикторов')}
                            </span>
                            <InputNumber
                                value={limits.speaker_models}
                                onChange={({value}) => value && setLimits({...limits, speaker_models: value})}
                            />
                        </label>
                        <label className='grid grid_rg-2'>
                            <span>
                                {trl('Хранилище, ГБ')}
                            </span>
                            <InputNumber
                                value={limits.storage_capacity / giga}
                                onChange={({value}) => value && setLimits({...limits, storage_capacity: value * giga})}
                            />
                        </label>
                        <label className='grid grid_rg-2'>
                            <span>
                                {trl('Одновременно обрабатываемых задач')}
                            </span>
                            <InputNumber
                                value={limits.task_count}
                                onChange={({value}) => value && setLimits({...limits, task_count: value})}
                            />
                        </label>
                        <label className='grid grid_rg-2'>
                            <span>
                                {trl('Символов в запросе на синтез')}
                            </span>
                            <InputNumber
                                value={limits.tts_characters_per_request}
                                onChange={({value}) => value && setLimits({...limits, tts_characters_per_request: value})}
                            />
                        </label>
                        <label className='grid grid_rg-2'>
                            <span>
                                {trl('Запросов на синтез в секунду')}
                            </span>
                            <InputNumber
                                value={limits.tts_requests_per_second}
                                onChange={({value}) => value && setLimits({...limits, tts_requests_per_second: value})}
                            />
                        </label>
                    </div>
                </div>
                <DialogFooter>
                    <Button
                        type='button'
                        onClick={() => submit()}
                    >
                        {trl('Применить')}
                    </Button>
                </DialogFooter>
            </form>
        </Dialog>
    )
}
