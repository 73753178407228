import {ErrorCodes} from '~/services/error'
import {formatError} from '~/utils/formatError'
import {getUseSharedReducer} from '~/utils/getUseSharedReducer'

import type {BackendError} from '~/services/error'

const defaultTimeout = 5000
let popupId = 0

export type PopupData = {
    id: number
    message: string
    type: 'error' | 'message'
    timeoutId: number
    close: () => void
}

type PopupAction =
    | {
        type: 'add'
        payload: PopupData
    }
    | {
        type: 'remove'
        payload: number
    }
    | {type: 'removeAll'}

function reducer(state: PopupData[], action: PopupAction) {
    switch (action.type) {
    case 'add':
        return [
            ...state,
            action.payload,
        ]

    case 'remove':
        return state.filter(({id}) => id != action.payload)

    case 'removeAll':
        return []
    }
}

export const usePopups = getUseSharedReducer(reducer, [])

export function showPopup(message: string, options?: {type?: PopupData['type'], timeout?: number}) {
    const timeout = Number.isFinite(options?.timeout) ? options?.timeout : defaultTimeout
    const timeoutId = timeout
        ? window.setTimeout(hideAll, timeout)
        : 0

    const popupData = {
        id: popupId++,
        message,
        type: options?.type || 'message',
        timeoutId,
        close: hideAll,
    }

    hideAll()
    usePopups.dispatch({type: 'add', payload: popupData})
}

export function showErrorPopup(error: BackendError): never {
    if (error.code != ErrorCodes.userCancel)
        showPopup(formatError(error), {type: 'error'})
    throw error
}

function hideAll() {
    usePopups.dispatch({type: 'removeAll'})
}
