import {delay} from '~/utils/delay'

export default function debatch<T, U, V>(
    interval: number,
    batchTransformer: (values: T[]) => U | Promise<U>,
    batchResponseParser: (value: T, response: U) => V | Promise<V>,
) {
    let values: T[] = []
    let promise: Promise<U> | undefined

    return (value: T): Promise<V> => {
        values.push(value)

        promise ||= delay(interval)
            .then(() => {
                const result = batchTransformer(values)
                promise = undefined
                values = []
                return result
            })

        return promise
            .then(response => batchResponseParser(value, response))
    }
}

export function getSharedDelay(interval: number) {
    let promise: Promise<void> | undefined

    return () =>
        promise ||= delay(interval)
            .then(() => promise = undefined)
}
