import LandingBox1 from './LandingBox1'
import LandingBox10 from './LandingBox10'
import LandingBox11 from './LandingBox11'
import LandingBox12 from './LandingBox12'
import LandingBox2 from './LandingBox2'
import LandingBox3 from './LandingBox3'
import LandingBox4 from './LandingBox4'
import LandingBox5 from './LandingBox5'
import LandingBox7 from './LandingBox7'
import LandingBox8 from './LandingBox8'
import LandingBox9 from './LandingBox9'

export default function() {
    return (
        <div className='landing row row_col-center'>
            <LandingBox1/> {/* Главная */}
            <LandingBox2/> {/* Область применения */}
            <LandingBox4/> {/* Demo */}
            <LandingBox3/> {/* Brand voice */}
            <LandingBox5/> {/* Функциональные особенности */}
            <LandingBox8/> {/* Преимущества технологии */}
            <LandingBox9/> {/* Тарифы */}
            <LandingBox10/> {/* Обсудим проект */}
            <LandingBox7/> {/* Вопросы и ответы */}
            <LandingBox11/> {/* Партнеры */}
            <LandingBox12/> {/* Footer */}
        </div>
    )
}

