import clsx from 'clsx'
import {Dropdown} from 'primereact/dropdown'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {trl} from '~/services/intl'
import {urlEncode} from '~/utils/sort'
import {mergeSearchString} from '~/utils/url'

import type {Sort} from '~/utils/sort'

export default function() {
    const [sort, setSort] = useState<Sort>({field: 'created_date', ascending: false})

    const navigate = useNavigate()

    const options = [
        {label: trl('Имя файла'), value: 'name'},
        {label: trl('Дата загрузки'), value: 'created_date'},
        {label: trl('Размер'), value: 'size'},
    ]

    useEffect(() => {
        navigate({search: mergeSearchString({sort: urlEncode(sort)})})
    }, [navigate, sort])

    return (
        <div className='files__sort-selector'>
            <div className='sort-selector'>
                <div className='row row_gap-2'>
                    <div className='col'>
                        <button onClick={() => setSort({field: sort.field || 'created_date', ascending: !sort.ascending})}>
                            <i className={clsx('pi', sort.ascending ? 'pi-sort-amount-down' : 'pi-sort-amount-up')}/>
                        </button>
                    </div>
                    <div className='col col_wide'>
                        <Dropdown
                            options={options}
                            value={sort.field}
                            onChange={event => setSort({field: event.value as Sort['field'], ascending: sort.ascending || false})}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
