import clsx from 'clsx'
import {Menu} from 'primereact/menu'
import {useRef} from 'react'

import type {MenuItem} from 'primereact/menuitem'

type Props = {
    items: MenuItem[]
    children: React.ReactNode
    className?: string
    containerClassName?: string
    containerStyle?: React.CSSProperties
    menuClassName?: string
    hover?: boolean
}

export default function WithMenu({
    items,
    children,
    containerClassName,
    containerStyle,
    className,
    menuClassName,
    ...props
}: Props) {
    const menu = useRef<Menu>(null)

    return (
        <span className={containerClassName} style={containerStyle}>
            <button
                type='button'
                className={className || 'button button_block'}
                onClick={event => menu.current?.toggle(event)}
                {...props}
            >
                {children}
            </button>
            <Menu
                className={clsx('menu', menuClassName)}
                id='_menu'
                model={items}
                ref={menu}
                popup
            />
        </span>
    )
}
