import {Paginator} from 'primereact/paginator'
import {useNavigate} from 'react-router-dom'

import {mergeSearchString, useSearchParams} from '~/utils/url'

type PaginationProps = {
    itemsTotal: number
    itemsPerPage: number
}
export default function Pagination({itemsTotal, itemsPerPage}: PaginationProps) {
    const navigate = useNavigate()
    const params = useSearchParams({page: '1'})
    const page = +params.page

    const handlePageChange = ({page: newPage}: {page: number}) => {
        if (page != newPage + 1)
            navigate(
                mergeSearchString({page: newPage + 1}),
                {replace: true}
            )
    }

    return <Paginator
        first={(page - 1) * itemsPerPage}
        rows={itemsPerPage}
        totalRecords={itemsTotal}
        onPageChange={handlePageChange}
    />
}
