import clsx from 'clsx'
import {useEffect, useMemo, useState} from 'react'
import {Outlet} from 'react-router-dom'

import SortSelector from './SortSelector'
import AsrParametersButton from '~/components/AsrParametersButton'
import FileFilterInput from '~/components/file/FileFilterInput'
import FileList from '~/components/file/FileList'
import FileListHeader from '~/components/file/FileListHeader'
import FileSelection from '~/components/file/FileSelection'
import FileUpload from '~/components/file/FileUpload'
import FileUploads from '~/components/file/FileUploads'
import MinuteUsage from '~/components/MinuteUsage'
import Pagination from '~/components/Pagination'
import StorageUsage from '~/components/StorageUsage'
import {searchFiles} from '~/services/file'
import {trl} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {currentUserChangeDispatcher} from '~/services/user'
import {urlDecode} from '~/utils/sort'
import {useSearchParams} from '~/utils/url'

import type {StorageFile} from '~/services/file'

const filesPerPage = 20

export default function() {
    const [files, setFiles] = useState<StorageFile[]>([])
    const [filesTotal, setFilesTotal] = useState(0)
    const searchParams = useSearchParams({sort: '', page: '1', filter: ''})
    const sort = useMemo(() => urlDecode(searchParams.sort), [searchParams.sort])
    const page = +searchParams.page
    const [menuIsOpened, setMenuIsOpened] = useState(false)

    useEffect(
        () => {
            const refresh = () =>
                searchFiles({
                    limit: filesPerPage,
                    offset: (page - 1) * filesPerPage,
                    sort,
                    filter: {name: searchParams.filter},
                })
                    .then(({files, total}) => {
                        setFiles(files)
                        setFilesTotal(total)
                    })
                    .catch(showErrorPopup)

            refresh()

            return currentUserChangeDispatcher.subscribe(refresh)
        },
        [page, searchParams.filter, sort]
    )

    return (
        <div className='page__main page__main_files'>
            <div className='files row row_stretch'>
                <div className={clsx('sidebar', menuIsOpened && 'sidebar_opened')}>
                    <div className='sidebar-container'>
                        <div className='sidebar-container__content'>
                            <StorageUsage/>
                            <MinuteUsage/>
                            <hr/>
                            <div className='file-upload row'>
                                <FileUpload/>
                                <AsrParametersButton/>
                            </div>
                            <div className='file-hint'>
                                {trl('Загружайте один или несколько файлов, максимальный размер файла 500 MБ')}
                            </div>
                            <FileUploads/>
                        </div>
                    </div>
                    <button onClick={() => setMenuIsOpened(!menuIsOpened)} className='sidebar-close'>
                        <i className='pi pi-times'/>
                    </button>
                </div>
                <div className='files-header'>
                    <div className='row'>
                        <div className='col sidebar-activator-place'>
                            <button onClick={() => setMenuIsOpened(!menuIsOpened)} className='sidebar-activator'>
                                <i className='pi pi-cloud-upload'/>
                            </button>
                        </div>
                        <div className='col col_wide'>
                            <FileFilterInput/>
                        </div>
                    </div>
                    <div className='row files__actions'>
                        <FileSelection files={files}/>
                    </div>
                    <SortSelector/>
                    <FileListHeader sort={sort}/>
                </div>
                <div className='files-body'>
                    <FileList files={files}/>
                </div>
                <div className='files-footer'>
                    <Pagination itemsTotal={filesTotal} itemsPerPage={filesPerPage}/>
                </div>
            </div>
            <Outlet/>
        </div>
    )
}
