import {useCurrentUser} from './user'
import {BACKEND} from '~/config'
import {handleHttpError} from '~/services/error'
import {interfaceLanguage} from '~/services/intl'
import {openWebsocket} from '~/services/websocket'
import {getUseSharedState} from '~/utils/getUseSharedState'
import {http} from '~/utils/http'

export class RecognitionTransform extends TransformStream<Int16Array | 'complete', StreamingResult> {
    constructor(modelId: string) {
        let socket: WebSocket

        super({
            async start(controller) {
                socket = await openWebsocket(`${BACKEND.websocket}/demo/asr/stream-recognize`)

                socket.send(JSON.stringify({model: modelId, only_new: true, sil_after_word_timeout_ms: 150}))

                socket.onmessage = (event: MessageEvent<string>) => {
                    const value: object = JSON.parse(event.data) as object

                    if (checkIsStreamingResult(value))
                        controller.enqueue(value)

                    if ('error' in value)
                        controller.error(value)
                }

                socket.onclose = () => controller.terminate()
                socket.onerror = event => controller.error(event)
            },
            transform(audioChunk) {
                if (socket.readyState != socket.OPEN)
                    throw 'streaming recognition not initialized'

                socket.send(audioChunk)
            },
            flush() {
                if (socket.readyState != socket.OPEN)
                    throw 'streaming recognition not initialized'

                socket.send('complete')
            },
        })
    }
}

type ResultChunk = {
    confidence: number
    loudness: number
    start_time: number
    end_time: number
    words: string[]
}

export type StreamingResult = {
    chunks: ResultChunk[]
    text: string
}

function checkIsStreamingResult(value: unknown): value is StreamingResult {
    return value != undefined &&
        typeof value == 'object' &&
        'text' in value &&
        'chunks' in value &&
        Array.isArray(value.chunks)
}

export type Model = {
    language: string
    model_id: string
    sample_rate: number
}

let streamModelCache: Promise<Model[]> | undefined

export function getStreamModels() {
    return streamModelCache ||= http.get<{models: Model[]}>(`${BACKEND.api}/demo/asr/stream-models`)
        .then(({models}) => models)
        // TODO убрать костыль, когда бэкенд начнет язык возвращать
        .then(models => models.map(model => ({
            ...model,
            language: model.model_id.split('_')[0],
        })))
        .catch(handleHttpError)
}

let modelCache: Promise<Model[]> | undefined

useCurrentUser.subscribe(() => {
    modelCache = undefined
    getModels()
        .then(inferDefaultModel)
        .then(model => {
            const asrParameters = useAsrParameters.getState()
            useAsrParameters.dispatch({
                ...asrParameters,
                model_ids: asrParameters.model_ids.length
                    ? asrParameters.model_ids
                    : [model.model_id],
            })
        })
})

export function getModels() {
    return modelCache ||= http.get<{models: Model[]}>(`${BACKEND.api}/asr/models`)
        .then(({models}) => models)
        .catch(handleHttpError)
}

export function inferDefaultModel(models: Model[]): Model {
    return getDefaultModel(models, interfaceLanguage) ||
        getDefaultModel(models, 'ru') ||
        getDefaultModel(models)
}

function getDefaultModel(models: Model[]): Model
function getDefaultModel(models: Model[], preferredLanguage: string): Model | undefined
function getDefaultModel(models: Model[], preferredLanguage?: string): Model | undefined {
    return models
        .find(({language}) => !preferredLanguage || language == preferredLanguage)
}

export type AsrParameters = {
    model_ids: string[]
    process_with_sd: boolean
    enable_automatic_punctuation: boolean
    word_to_number: boolean
    sentiment_analysis: boolean
    diarization: boolean
    thresholds_greedy: number
    thresholds_am: number
}

export const useAsrParameters = getUseSharedState<AsrParameters>({
    model_ids: [],
    process_with_sd: true,
    enable_automatic_punctuation: false,
    word_to_number: false,
    sentiment_analysis: false,
    diarization: false,
    thresholds_greedy: 30,
    thresholds_am: 0.3,
})
