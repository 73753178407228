import {Button} from 'primereact/button'
import {Checkbox} from 'primereact/checkbox'
import {InputNumber} from 'primereact/inputnumber'
import {useState, type ComponentProps, useEffect} from 'react'

import DialogFooter from './dialog/DialogFooter'
import AsrModelSelector from '~/components/AsrModelSelector'
import Dialog from '~/components/dialog/Dialog'
import {useAsrParameters} from '~/services/asr'
import {trl, trlObject} from '~/services/intl'

import type {KeysOfType} from '~/utils/typeHelpers'

type Props = Omit<ComponentProps<typeof Dialog>, 'onHide'> & {onDone: () => void}

export default function({onDone, ...props}: Props) {
    const [parameters, setParameters] = useAsrParameters()
    const [editableParameters, setEditableParameters] = useState(parameters)

    useEffect(() => {
        setEditableParameters(parameters)
    }, [props.visible, parameters])

    function toggleParameter(parameter: KeysOfType<typeof parameters, boolean>) {
        setEditableParameters({
            ...editableParameters,
            [parameter]: !editableParameters[parameter],
        })
    }

    function setParameter<T>(parameter: KeysOfType<typeof parameters, T>, value: T) {
        setEditableParameters({
            ...editableParameters,
            [parameter]: value,
        })
    }

    return (
        <Dialog
            {...props}
            className='dialog dialog_medium-fix'
            header={trl('Настройки распознавания речи')}
            onHide={onDone}
        >
            <form className='dialog__form'>
                <div className='dialog__content'>
                    <div className='grid grid_rg-6'>
                        <AsrModelSelector
                            modelIds={editableParameters.model_ids}
                            onChange={modelIds => setEditableParameters(parameters => ({
                                ...parameters,
                                ...modelIds.length > 1 && {process_with_sd: true},
                                model_ids: modelIds,
                            }))}
                        />
                        <label className='row row_gap-2'>
                            <Checkbox
                                disabled={editableParameters.model_ids.length > 1}
                                checked={editableParameters.process_with_sd}
                                onChange={() => toggleParameter('process_with_sd')}
                            />
                            {trlObject('_asr.parameters').process_with_sd}
                        </label>
                        <label className='row row_gap-2'>
                            <Checkbox
                                checked={editableParameters.enable_automatic_punctuation}
                                onChange={() => toggleParameter('enable_automatic_punctuation')}
                            />
                            {trlObject('_asr.parameters').enable_automatic_punctuation}
                        </label>
                        <label className='row row_gap-2'>
                            <Checkbox
                                checked={editableParameters.word_to_number}
                                onChange={() => toggleParameter('word_to_number')}
                            />
                            {trlObject('_asr.parameters').word_to_number}
                        </label>
                        <label className='row row_gap-2'>
                            <Checkbox
                                checked={editableParameters.sentiment_analysis}
                                onChange={() => toggleParameter('sentiment_analysis')}
                            />
                            {trlObject('_asr.parameters').sentiment_analysis}
                        </label>
                        <label className='row row_gap-2'>
                            <Checkbox
                                checked={editableParameters.diarization}
                                onChange={() => toggleParameter('diarization')}
                            />
                            {trlObject('_asr.parameters').diarization}
                        </label>
                        <label className='grid grid_rg-2'>
                            {trlObject('_asr.parameters').thresholds_greedy}
                            <InputNumber
                                value={editableParameters.thresholds_greedy}
                                onChange={({value}) => setParameter<number>('thresholds_greedy', value || 0)}
                            />
                        </label>
                        <label className='grid grid_rg-2'>
                            {trlObject('_asr.parameters').thresholds_am}
                            <InputNumber
                                value={editableParameters.thresholds_am}
                                onChange={({value}) => setParameter<number>('thresholds_am', value || 0)}
                            />
                        </label>
                    </div>
                </div>
                <DialogFooter>
                    <Button
                        type='button'
                        disabled={editableParameters.model_ids.length === 0}
                        onClick={() => {
                            setParameters(editableParameters)
                            onDone()
                        }}
                    >
                        {trl('Применить')}
                    </Button>
                </DialogFooter>
            </form>
        </Dialog>
    )
}
