import {Button} from 'primereact/button'
import {Checkbox} from 'primereact/checkbox'
import {InputText} from 'primereact/inputtext'
import {Password} from 'primereact/password'
import {useState} from 'react'

import {trl, trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {register} from '~/services/user'
import {validateEmail} from '~/utils/validateEmail'

import type {FormEvent} from 'react'

export default function() {
    const [form, setForm] = useState({
        name: '',
        email: '',
        company: '',
        password: '',
        password2: '',
        personalData: false,
        advertisements: false,
    })

    const {
        name,
        email,
        company,
        password,
        password2,
        personalData,
        advertisements,
    } = form
    const updateForm = (formData: Partial<typeof form>) => setForm({...form, ...formData})
    const [ready, setReady] = useState(true)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const submit = (event: FormEvent) => {
        event.preventDefault()

        setReady(false)

        register(email, password, name, company)
            .then(() => setIsSubmitted(true))
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    return (
        <div className='page__main'>
            <form className='register custom-controls-register'>
                <div className='row row_tall row_center register__row'>
                    <div className='register__card row row_col-center row_gap-14'>
                        {isSubmitted
                            ? <>
                                <div className='register__caption row row_center'>
                                    {trl('_register.success.header')}
                                </div>
                                <div className='register__recover-title'>
                                    {trlObject('_register.success.message').join('')}
                                </div>
                            </>
                            : <>
                                <div className='register__caption row row_center'>
                                    {trl('Регистрация')}
                                </div>
                                <div className='grid grid_rg-10 register__controls'>
                                    <InputText
                                        type='text'
                                        placeholder={trl('Имя')}
                                        value={name}
                                        onChange={({target}) => updateForm({name: target.value})}
                                    />
                                    <InputText
                                        type='company'
                                        placeholder={trl('Компания')}
                                        value={company}
                                        onChange={({target}) => updateForm({company: target.value})}
                                    />
                                    <InputText
                                        type='text'
                                        placeholder={trl('E-mail')}
                                        value={email}
                                        onChange={({target}) => updateForm({email: target.value})}
                                    />
                                    <Password
                                        type='password'
                                        placeholder={trl('Придумайте пароль')}
                                        value={password}
                                        feedback={false}
                                        onChange={({target}) => updateForm({password: target.value})}
                                        toggleMask
                                    />
                                    <div className='row row_gap-2'>
                                        <Password
                                            className='row'
                                            type='password'
                                            placeholder={trl('Повторите пароль')}
                                            value={password2}
                                            feedback={false}
                                            onChange={({target}) => updateForm({password2: target.value})}
                                            toggleMask
                                        />
                                        {password2 != password &&
                                            <span className='text text_assertive text_small'>{trl('Пароли не совпадают')}</span>
                                        }
                                    </div>
                                    <div className='row row_cropped row_gap-6'>
                                        <div className='register__inputs_checkbox row row_nowrap row_gap-2'>
                                            <Checkbox
                                                checked={advertisements}
                                                type='checkbox'
                                                onChange={({checked}) => updateForm({advertisements: !!checked})}
                                            />
                                            <span>
                                                {trlObject('_landing.boxes')[10].form.advertisements}
                                            </span>
                                        </div>
                                        <div className='register__inputs_checkbox row row_nowrap row_gap-2'>
                                            <Checkbox
                                                checked={personalData}
                                                type='checkbox'
                                                onChange={({checked}) => updateForm({personalData: !!checked})}
                                            />
                                            <span>
                                                {trlObject('_landing.boxes')[10].form.personalData[0]}&nbsp;
                                                <a target='_blank' href='/static/policy.pdf' className='link'>
                                                    {trlObject('_landing.boxes')[10].form.personalData[1]}
                                                </a>.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row row_center'>
                                    <Button
                                        className='landing-blue-button landing-blue-button_large-x'
                                        onClick={submit}
                                        disabled={!ready || !validateEmail(email) || !password || password != password2}
                                    >
                                        {trl('Зарегистрироваться')}
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}
