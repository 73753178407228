import {InputText} from 'primereact/inputtext'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {trl} from '~/services/intl'
import {useDebounce} from '~/utils/debounce'
import {mergeSearchString, useSearchParams} from '~/utils/url'

const filterApplyTimeout = 1000

export default function() {
    const navigate = useNavigate()
    const searchParams = useSearchParams({filter: ''})
    const [filter, filterDebounced, setFilter] = useDebounce(searchParams.filter, filterApplyTimeout)

    useEffect(
        () => navigate(mergeSearchString({filter: filterDebounced || undefined})),
        [filterDebounced, navigate]
    )

    return (
        <label className='files-search'>
            <span className='p-input-icon-right row'>
                <i className='pi pi-search'/>
                <InputText
                    className='files-search__input'
                    placeholder={trl('Найти')}
                    value={filter}
                    onChange={event => setFilter(event.target.value)}
                />
            </span>
        </label>
    )
}
