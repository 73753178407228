export function toggleMapKeyMutable<K, V>(map: Map<K, V>, key: K, value?: V, force = value != undefined && !map.has(key)) {
    if (force)
        // @ts-expect-error value обязательно типа V если force оказался истинным, но TS не понимает
        map.set(key, value)
    else
        map.delete(key)
}

export function toggleMapKey<K, V>(map: Map<K, V>, key: K, value?: V, force = value != undefined && !map.has(key)) {
    const resultMap = new Map(map)

    toggleMapKeyMutable(resultMap, key, value, force)

    return resultMap
}
