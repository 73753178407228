import {Button} from 'primereact/button'
import {useEffect, useState} from 'react'

import MicrophoneIndicator from '../MicrophoneIndicator'
import Timer from '../Timer'
import BottomScroller from '~/components/BottomScroller'
import RecognitionModelSelector from '~/components/RecognitionModelSelector'
import {RecognitionTransform, getStreamModels, inferDefaultModel} from '~/services/asr'
import {trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {convertFloat32ToInt16Array, createMicrophoneStream} from '~/utils/audioRecorder'
import {createFabric} from '~/utils/functional'
import {MapTransform, PeekIdentityTranform} from '~/utils/streamTransformHelpers'

const recognitionDemoSecondsLimit = 60

export default function() {
    const {recordButton, /* checkboxes, */ placeholder, stopButton} =
        trlObject('_landing.boxes')['4'].blocks.speechRecognition

    // eslint-disable-next-line no-spaced-func
    const [streaming, setStreaming] = useState<{close: () => void}>()
    const [modelId, setModelId] = useState('')
    const [microphoneValue, setMicrophoneValue] = useState(() => new Float32Array)
    const [text, setText] = useState('')
    // const [checkboxesState, setChekboxesState] = useState({punctuate: false, numeral: false, emotions: false})
    // const updateCheckbox = (data: Partial<typeof checkboxesState>) => setChekboxesState({...checkboxesState, ...data})

    useEffect(
        () => {
            getStreamModels()
                .then(inferDefaultModel)
                .then(model => setModelId(model.model_id))
        },
        []
    )

    const start = () => {
        setText(' ')

        const microphoneStream = createMicrophoneStream(8000)

        microphoneStream
            .pipeThrough(new MapTransform(createFabric(Float32Array)))
            .pipeThrough(new PeekIdentityTranform(setMicrophoneValue))
            .pipeThrough(new MapTransform(convertFloat32ToInt16Array))
            .pipeThrough(new RecognitionTransform(modelId))
            .pipeTo(new WritableStream({write: ({text}) => setText(aText => `${aText}\n${text}`.trim())}))
            .catch(showErrorPopup)
            .finally(() => setStreaming(undefined))

        setStreaming(microphoneStream)
    }

    const stop = streaming?.close

    useEffect(
        () => streaming?.close,
        [streaming]
    )

    return (
        <div className='landing-recognition row row_col row_gap-6'>
            <div className='landing-recognition__content row row_gap-14 row_nowrap row_top'>
                <div className='row row_col'>
                    <BottomScroller
                        className='landing-recognition__textarea'
                        tag='textarea'
                        value={text}
                        placeholder={placeholder}
                        readOnly
                    />
                </div>
                <div className='row row_col row_gap-10'>
                    <RecognitionModelSelector
                        className='landing-recognition__model-selector'
                        disabled={Boolean(streaming)}
                        modelId={modelId}
                        onChange={setModelId}
                    />

                    {/* TODO ожидание поддержки бекенда */}
                    {/* <div className='row row_col row_gap-6'>
                        {typedObjectEntries(checkboxes).map(([key, name]) =>
                            <label key={key} className='row row_nowrap row_gap-2'>
                                <Checkbox
                                    checked={checkboxesState[key]}
                                    onChange={({checked}) => updateCheckbox({[key]: checked})}
                                />
                                <span className='landing-recognition__checkbox-label'>{name}</span>
                            </label>
                        )}
                    </div> */}
                </div>
            </div>
            <div className='landing-recognition__footer row row_col row_gap-4'>
                <div className='landing-recognition__footer_buttons row row_gap-2'>
                    <Button
                        type='button'
                        className='p-button-outlined landing-blue-outlined-button'
                        onClick={streaming ? stop : start}
                    >
                        {streaming ? stopButton : recordButton}
                    </Button>
                    {!streaming || <>
                        <MicrophoneIndicator samples={microphoneValue}/>
                        <Timer
                            seconds={recognitionDemoSecondsLimit}
                            onElapse={stop}
                        />
                    </>}
                </div>
            </div>
        </div>
    )
}
