import clsx from 'clsx'
import {OverlayPanel} from 'primereact/overlaypanel'
import {useRef} from 'react'

import Icon from './Icon'
import UserDev from './UserDev'
import UserEmail from './UserEmail'
import UserLogout from './UserLogout'

type Props = {
    className?: string
}

export default function({className}: Props) {
    const overlayPanelRef = useRef<OverlayPanel>(null)

    return (
        <>
            <div className={clsx('header__item row row_nowrap', className)}>
                <UserDev/>
                <button onClick={event => overlayPanelRef.current?.toggle(event)}>
                    <Icon name='user' className='header__icon'/>
                </button>
            </div>
            <OverlayPanel
                className='user-info__info_panel'
                ref={overlayPanelRef}
            >
                <UserEmail/>
                <UserLogout/>
            </OverlayPanel>
        </>
    )
}
