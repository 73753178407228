export type Sort<T = string> = {
    field: T
    ascending: boolean
}

export function urlEncode({field, ascending}: Sort) {
    if (field)
        return `${ascending ? '' : '-'}${field}`

    return undefined
}

export function urlDecode(sort = ''): Sort {
    const [, order, field] = /^([+-]?)(.*)/.exec(sort) || []
    return {
        field: field || '',
        ascending: order != '-',
    }
}
