import {useNavigate} from 'react-router-dom'

import FileListColumnHeaderSortable from '../file/FileListColumnHeaderSortable'
import {trl} from '~/services/intl'
import {urlEncode} from '~/utils/sort'
import {mergeSearchString} from '~/utils/url'

import type {Sort} from '~/utils/sort'

type Props = {
    sort: Sort
}

export default function({sort}: Props) {
    const navigate = useNavigate()

    const navigateSort = (sort: Sort) => {
        navigate({search: mergeSearchString({sort: urlEncode(sort)})})
    }

    return (
        <ul className='user-list-row user-list-row_header'>
            <li className='user-list-row__col user-list-row__col_login'>
                <FileListColumnHeaderSortable
                    className='user-list-row__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='username'
                    onSortChange={navigateSort}
                >
                    E-mail
                </FileListColumnHeaderSortable>
            </li>
            <li className='user-list-row__col user-list-row__col_center'>
                <FileListColumnHeaderSortable
                    className='user-list-row__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='role'
                    onSortChange={navigateSort}
                >
                    {trl('Роль')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='user-list-row__col user-list-row__col_center'>
                <FileListColumnHeaderSortable
                    className='user-list-row__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='status'
                    onSortChange={navigateSort}
                >
                    {trl('Статус')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='user-list-row__col user-list-row__col_center'>
                <FileListColumnHeaderSortable
                    className='user-list-row__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='usage.seconds'
                    onSortChange={navigateSort}
                >
                    {trl('Минуты')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='user-list-row__col user-list-row__col_center'>
                <FileListColumnHeaderSortable
                    className='user-list-row__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='usage.storage_capacity'
                    onSortChange={navigateSort}
                >
                    {trl('Хранилище')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='user-list-row__col user-list-row__col_center'>
                <FileListColumnHeaderSortable
                    className='user-list-row__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='usage.task_count'
                    onSortChange={navigateSort}
                >
                    {trl('Задачи')}
                </FileListColumnHeaderSortable>
            </li>
            <li className='user-list-row__col user-list-row__col_center'>
                <FileListColumnHeaderSortable
                    className='user-list-row__sort-button row row_gap-2 row_center'
                    sort={sort}
                    field='created_date'
                    onSortChange={navigateSort}
                >
                    {trl('Дата регистрации')}
                </FileListColumnHeaderSortable>
            </li>
        </ul>
    )
}
