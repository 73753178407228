import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import Dialog from '../dialog/Dialog'
import Audio from '~/components/Audio'
import LoadChat from '~/components/LoadChat'
import OverallProgress from '~/components/OverallProgress'
import {getFile} from '~/services/file'
import {showErrorPopup} from '~/services/popup'
import {formatDate} from '~/utils/formatDate'
import {mergeSearchString} from '~/utils/url'

import type {StorageFile} from '~/services/file'

export default function() {
    const {id = ''} = useParams()
    const [file, setFile] = useState<StorageFile>()
    const navigate = useNavigate()

    useEffect(
        () => {
            getFile(id)
                .then(setFile)
                .catch(showErrorPopup)
        },
        [id]
    )

    if (!file)
        return <OverallProgress/>

    return (
        <Dialog
            className='dialog_large-fix dialog_full-height file-dialog'
            visible
            draggable={false}
            onHide={() => navigate(`..${mergeSearchString({})}`)}
            header={
                <div className='grid grid_rg-5 file-dialog-header'>
                    <span className='file-dialog__title'>
                        {file.name}
                    </span>
                    <span className='file-dialog__subtitle'>
                        {formatDate(new Date(file.created_date))}
                    </span>
                    <Audio file={file}/>
                </div>
            }
        >
            <div className='file-dialog__content'>
                <LoadChat file={file}/>
            </div>
        </Dialog>
    )
}
