import {Tooltip} from 'primereact/tooltip'

import type {TooltipProps} from 'primereact/tooltip'

export default function({...props}: TooltipProps) {
    return <Tooltip
        showDelay={1000}
        hideDelay={300}
        {...props}
    />
}
