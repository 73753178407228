import FileRow from '~/components/file/FileRow'

import type {StorageFile} from '~/services/file'

type Props = {
    files: StorageFile[]
}

export default function({files}: Props) {
    return (
        <div className='file-list'>
            {files.map(file => <FileRow key={file.id} file={file}/>)}
        </div>
    )
}

