import {ErrorCodes} from '~/services/error'
import {trlObject} from '~/services/intl'

import type {IntlMap} from '~/intl/ru'
import type {BackendError} from '~/services/error'

function checkErrorCodeKnown(code: string | number): code is keyof IntlMap['_error'] {
    return code in trlObject('_error')
}

export function formatError(error: BackendError) {
    const errorMap = trlObject('_error')
    const errorCodeString = error.code

    if (checkErrorCodeKnown(errorCodeString))
        return errorMap[errorCodeString]

    if (error.message)
        return error.message

    return errorMap[ErrorCodes.somethingGoneWrong]
}
