export type RequestBody = {
    required: boolean
    value: Record<string, unknown>
    contentType: 'multipart/form-data' | 'application/json'
}

export type Method = {
    description: string
    method: {
        url: string
        type: 'get' | 'post' | 'delete' | 'patch'
    }
    parameters?: {
        search?: {
            name: string
            type: 'string' | 'boolean' | 'integer' | 'array[string]'
            description: string
        }[]
        path?: {
            name: string
            type: 'string' | 'boolean' | 'number'
            description: string
            isRequired: boolean
        }[]
        requestBody?: RequestBody
    }
    responses: {
        code: number
        title: string
        description?: string
        contentType?: string
        response: unknown
    }[]
}

export type ApiMethods = {
    [key: string]: Method[]
}

export function loadApiDocumentationJSON(language: string) {
    return import(`~/public/static/api-documentation/api-doc.${language}.json`) as Promise<ApiMethods>
}

export const preparePath = (method: string) => method.replace(/[{}]/g, '').replace(/\W/g, '-')
