import clsx from 'clsx'

type Props<T> = {
    tabs: readonly {
        value: T
        label: string
        disabled?: boolean
        visible?: boolean
    }[]
    onTabChange: (tab: T) => void
    activeTab?: T
    classNamesWrapper: string
}

export function Tab <T>({tabs, onTabChange, classNamesWrapper, activeTab}: Props<T>) {
    return <div className={classNamesWrapper}>
        <div className='row row_gap-8 row_nowrap test'>
            {tabs.map((tab, index) =>
                (!('visible' in tab) || tab.visible) &&
                <button
                    key={index}
                    type='button'
                    className={clsx('tab', activeTab == tab.value && 'tab_active')}
                    onClick={() => onTabChange(tab.value)}
                    disabled={tab.disabled}
                >
                    {tab.label}
                </button>
            )}
        </div>
    </div>
}
