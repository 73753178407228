import {trl} from '~/services/intl'
import {usePopups} from '~/services/popup'

import type {PopupData} from '~/services/popup'

type PopupProps = Pick<PopupData, 'message' | 'type' | 'close'>

function Popup({message, type, close}: PopupProps) {
    return (
        <div className={`popup popup_${type}`} role='alert' aria-live='assertive'>
            <div className='popup__body'>
                <div className='popup__title'>
                    {trl(`_popup.${type}`)}
                </div>
                <div className='popup__message'>
                    {message}
                </div>
            </div>
            <button
                className='popup__close'
                onClick={close}
            >
                <i className='pi pi-times'/>
            </button>
        </div>
    )
}

export default function Popups() {
    const [popups] = usePopups()

    return (
        <>
            {popups.map(({id, message, type, close}) =>
                <Popup
                    key={id}
                    message={message}
                    type={type}
                    close={close}
                />,
            )}
        </>
    )
}
