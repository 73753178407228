import clsx from 'clsx'
import {useMemo, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'

import Icon from './Icon'
import UserDev from './UserDev'
import UserEmail from './UserEmail'
import UserLogout from './UserLogout'
import UserInfo from '~/components/UserInfo'
import {trl} from '~/services/intl'
import {useCurrentUser} from '~/services/user'

const links = [
    {to: '/', title: trl('О платформе')},
    // {to: '/api', title: trl('API')},
    {to: '/files', title: trl('Распознавание речи'), userOnly: true},
    {to: '/synthesis', title: trl('Синтез речи'), userOnly: true},
    {to: '/documentation', title: trl('Документация')},
]

const buttons = [
    {to: '/login', title: trl('Войти'), className: 'p-button p-component landing-blue-button header_button'},
    {to: '/register', title: trl('Регистрация'), className: 'p-button p-button-outlined p-component landing-blue-outlined-button header_button'},
]

export default function() {
    const [currentUser] = useCurrentUser()
    const [showMenu, setShowMenu] = useState(false)
    const items = links.filter(({userOnly}) => currentUser || !userOnly)

    const itemComponents = useMemo(
        () => items
            .filter(({userOnly}) => currentUser || !userOnly)
            .map(({to, title}) =>
                <NavLink
                    onClick={() => setShowMenu(false)}
                    key={to}
                    className={({isActive}) => clsx('header__link', isActive && 'header__link_active', showMenu && 'header__link_mobile')}
                    to={to}
                >
                    {title}
                </NavLink>
            ),
        [currentUser, items, showMenu]
    )

    const buttonComponents = useMemo(
        () => buttons.map(({title, to, className}) =>
            <Link
                key={to}
                onClick={() => setShowMenu(false)}
                className={className}
                to={to}
                replace
            >
                {title}
            </Link>
        ),
        []
    )

    return (
        <div className='header row row_col-center'>
            <div className='header__content row row_nowrap row_noshrink row_gap-10 row_between'>
                <NavLink className='header__item' to='https://3itech.ru/'>
                    <Icon name='logoFooter' className='header__item header__logo'/>
                </NavLink>

                <div className='header__item row row_min-content row_nowrap row_gap-16 only-desktop'>
                    {itemComponents}
                </div>
                {currentUser &&
                    <UserInfo className='only-desktop'/>
                }
                {!currentUser &&
                    <div className='header__item row row_nowrap row_gap-2 only-desktop'>
                        {buttonComponents}
                    </div>
                }

                <button className='header__item not-desktop' onClick={() => setShowMenu(!showMenu)}>
                    <Icon name='burger' className='header__burger'/>
                </button>
                {showMenu &&
                    <div
                        className='header__dropdown-menu not-desktop grid grid_rg-4'
                    >
                        <div className='row row_right'>
                            <button className='header__item not-desktop' onClick={() => setShowMenu(!showMenu)}>
                                <Icon name='burger' className='header__burger'/>
                            </button>
                        </div>

                        <div className='grid grid_rg-0'>
                            {currentUser && <UserEmail/>}
                            {itemComponents}
                            {currentUser && <>
                                <hr/>
                                <UserDev textMode/>
                                <UserLogout/>
                            </>
                            }
                        </div>

                        {!currentUser &&
                            <div className='row row_center row_gap-2'>
                                {buttonComponents}
                            </div>
                        }

                    </div>
                }
            </div>
        </div>
    )
}
