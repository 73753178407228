import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import OverallProgress from '../OverallProgress'
import {initialRouteDocumentationPage} from '~/components/documentation/ApiPage'
import {interfaceLanguage} from '~/services/intl'
import {getStaticResourceText} from '~/utils/staticResource'

export default function() {
    const {topic = ''} = useParams()
    const [text, setText] = useState('')
    const navigate = useNavigate()

    useEffect(
        () => {
            getStaticResourceText(`/documentation/${topic}.${interfaceLanguage}.html`)
                .then(setText)
                .catch(() => navigate(initialRouteDocumentationPage))
        },
        [navigate, topic]
    )

    if (!text)
        return (
            <div className='documentation__text'>
                <OverallProgress/>
            </div>
        )

    return (
        <div className='documentation__text'>
            <div className='documentation__text-wrapper'>
                <div className='documentation__text-content' dangerouslySetInnerHTML={{__html: text}}/>
            </div>
        </div>
    )
}

