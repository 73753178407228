import {useCurrentUser} from '~/services/user'

export default function() {
    const [currentUser] = useCurrentUser()

    if (!currentUser)
        return null

    return <span className='user-info__info_email'>
        {currentUser.username}
    </span>
}
