import clsx from 'clsx'
import {useEffect, useState} from 'react'

import ChatView from '~/components/ChatView'
import OverallProgress from '~/components/OverallProgress'
import {getTaskResult, searchTaskForFile} from '~/services/file'
import {trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'

import type {StorageFile, AsrTaskResult} from '~/services/file'

type Props = {
    file: StorageFile
}

export default function({file}: Props) {
    const [task, setTask] = useState<AsrTaskResult>()
    const [ready, setReady] = useState(false)

    useEffect(
        () => {
            searchTaskForFile(file.id)
                .then(({id}) => getTaskResult(id))
                .then(setTask)
                .catch(showErrorPopup)
                .finally(() => setReady(true))
        },
        [file.id]
    )

    if (!ready)
        return <OverallProgress/>

    if (!task)
        return null

    return task.status == 'process' || task.status == 'error'
        ? <span className={clsx('text text_big row row_center', task.status == 'error' && 'text_assertive')}>
            {trlObject('_task.status')[task.status]}
        </span>
        : <ChatView taskResult={task}/>
}
