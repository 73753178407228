
import Icon from '../Icon'
import {trl, trlObject} from '~/services/intl'

export default function() {
    const {header, subtitle, blocks} = trlObject('_landing.boxes')['3']
    return (
        <div className='landing-box-3 row row_nowrap row_col-center'>
            <h1 className='landing-box-3__header landing__header'>
                {header}
            </h1>
            <small className='landing-box-3__subtitle landing__subtitle'>{subtitle}</small>
            <div className='landing-box-3__cards row row_center row_gap-8 '>
                {blocks.map((texts, index) =>
                    <div key={index} className='landing-box-3__card row row_col-center row_gap-6 row_start'>
                        <Icon name={`3-${index}`} className='landing-box-3__card_icon'/>
                        <div className='landing__text landing-box-3__card_texts'>
                            {texts}
                        </div>
                    </div>
                )}
            </div>
            <a
                className='p-button landing__button landing-blue-button landing-blue-button_large'
                href='#suggestForm'
            >
                {trl('Подать заявку')}
            </a>
        </div>
    )
}
