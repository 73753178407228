import React from 'react'
import {createRoot} from 'react-dom/client'

import App from './components/App'
import OverallProgress from './components/OverallProgress'
import {loadIntl} from '~/services/intl'
import {getCurrentUser} from '~/services/user'
import 'reset-css'
import './styles/index.scss'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import 'core-js/features/math/clamp'

import type {} from './typeAugmentation/cssCustomProperties'

require.context('./assets/icons/', false, /\.svg$/)

const rootElement = document.querySelector('#root') as Element
const root = createRoot(rootElement)

root.render(
    <div className='page'>
        <div className='page__main page__main_no-header'>
            <OverallProgress/>
        </div>
    </div>
)

Promise.all([
    loadIntl(),
    getCurrentUser(),
])
    .catch(console.error)
    .then(() => {
        root.render(
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        )
    })
