import {initialRouteDocumentationPage} from '../documentation/ApiPage'
import Icon from '../Icon'
import {trl, trlObject} from '~/services/intl'

const year = new Date().getFullYear()

export default function() {
    const {about, api, docs, politics, social, contacts} = trlObject('_landing.boxes')['12']

    return <div className='landing-box-12 row row_col-center'>
        <span className='landing-box-12__content grid grid_col-3'>
            <span className='row row_col row_start row_gap-14'>
                <div className='row row_top row_gap-10'>
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://3itech.ru/'
                    >
                        <Icon className='landing-box-12__icon-logo' name='logoFooter'/>
                    </a>
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://sk.ru/'
                        className='landing-box-12__icon-participantSK'
                    />
                    <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://fasie.ru/'
                        className='landing-box-12__icon-participantFasie'
                    />
                </div>
                <span className='row row_col row_gap-8'>
                    <a href='/'>{about}</a>
                    <a href='/#tariff'>{trl('Тарифы')}</a>
                    {/* TODO рефактор - убрать хардкод на маршрут для первого метода АПИ
                        http://gitlab.lan/data-plexus/frontend/vox-react/merge_requests/153#note_171877
                    */}
                    <a href={`${initialRouteDocumentationPage}/oauth/post-token`}>{api}</a>
                    <a href={initialRouteDocumentationPage}>{docs}</a>
                </span>
            </span>
            <span className='landing-box-12__contacts row row_col row_gap-14'>
                <span className='row row_col row_gap-8'>
                    {Object.entries(contacts).map(([icon, [label, href]], index) =>
                        <span className='row row_nowrap row_gap-4' key={index}>
                            <Icon className='landing-box-12__icon' name={icon}/>
                            <a
                                key={index}
                                href={href}
                                {...label == 'location' && {
                                    target: '_blank',
                                    rel: 'noreferrer',
                                }}
                            >
                                {label}
                            </a>
                        </span>
                    )}
                </span>
                <span className='not-pad'>{politics}</span>
            </span>
            <span className='row row_col row_gap-4'>
                <span className='landing-box-12__social row row_center text text_center'>{social.title}</span>
                <span className='landing-box-12__social row row_center row_gap-8'>
                    {social.icons.map(([icon, href], index) =>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            key={index}
                            href={href}
                        >
                            <Icon className='landing-box-12__icon-social' name={icon}/>
                        </a>
                    )}
                </span>
            </span>
            <span className='only-pad'>{politics}</span>
        </span>
        <div className='landing-box-12__footer row row_col-center row_center'>
            <div className='landing-box-12__footer_content row row_center'>
                <span className='landing-box-12__footer_copyright text text_nowrap'>
                    {trl('© %0 3iTech Все права принадлежат ООО “ДСС Лаб”', year)}
                </span>
            </div>
        </div>
    </div>
}

