import {useCallback, useMemo, useState} from 'react'

export default function debounce<F extends unknown[]>(func: (...args: F) => void, interval: number) {
    let timeoutId: number

    return (...args: F) => {
        clearTimeout(timeoutId)
        timeoutId = window.setTimeout(() => func(...args), interval)
    }
}

export function useDebounce<T>(initialValue: T, timeout: number) {
    const [value, setValue] = useState(initialValue)
    const [debouncedValue, setDebouncedValue] = useState(initialValue)

    const setDebounced = useMemo(
        () => debounce(setDebouncedValue, timeout),
        [timeout]
    )

    const set = useCallback(
        (value: T) => {
            setValue(value)
            setDebounced(value)
        },
        [setDebounced]
    )

    return [value, debouncedValue, set] as const
}
