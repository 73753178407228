import {interfaceLanguage} from '~/services/intl'

const formatters = [
    'byte',
    'kilobyte',
    'megabyte',
    'gigabyte',
    'terabyte',
    'petabyte',
]
    .map(unit => ({style: 'unit', unit, maximumSignificantDigits: 3}))
    .map(options => new Intl.NumberFormat(interfaceLanguage, options))
    .map(({format}) => format)

export const formatSize = (value: number) => {
    const exponent = Math.log(Math.abs(value)) / Math.log(1000) | 0

    return formatters[exponent](value / 1024 ** exponent)
}
