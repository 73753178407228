import Icon from './Icon'
import {trl} from '~/services/intl'
import {showPopup} from '~/services/popup'

import type {ButtonHTMLAttributes} from 'react'

export function CopyButton(
    {children, className = 'copy-button', ...props}:
    {children: string, className?: string} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'className'>
) {
    return (
        <button
            className={className}
            type='button'
            onClick={() => navigator
                .clipboard
                .writeText(children)
                .then(() => showPopup(trl('Скопировано')))
            }
            {...props}
        >
            <Icon name='copy-line'/>
        </button>
    )
}
