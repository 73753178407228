import {Outlet} from 'react-router-dom'

import DocumentationSidebar from './DocumentationSidebar'

export default function() {
    return (
        <div className='page__main'>
            <div className='documentation'>
                <DocumentationSidebar/>
                <Outlet/>
            </div>
        </div>
    )
}
