import {useDialogs} from '~/services/confirmationDialog'

export default function ModalDialogs() {
    const [dialogs] = useDialogs()

    return (
        <>
            {dialogs.map(({id, Component, props}) => <Component key={id} {...props}/>)}
        </>
    )
}
